/* 
export const openai = new AzureOpenAI({
  apiKey: process.env.REACT_APP_AZURE_OPENAI_API_KEY,
  apiVersion: '2024-08-01-preview',
  endpoint: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
  dangerouslyAllowBrowser: true,
})
*/

export const anthropicBedrock = async (prompt: any) => {
  const url = process.env.REACT_APP_PUBLIC_LAMBDA_ENDPOINT!
  const messages = prompt.messages.map((message: any) => ({
    role: message.role,
    content: message.content.replace(
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDFFF]/g,
      ''
    ),
  }))
  const body = {
    action: 'bedrock',
    prompt: {
      max_tokens: 4096,
      ...prompt,
      messages,
    },
  }
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await response.json()
  return data.message
}

export const novaBedrock = async (prompt: any) => {
  const url = process.env.REACT_APP_PUBLIC_LAMBDA_ENDPOINT!
  const body = {
    action: 'general_bedrock',
    prompt,
  }
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await response.json()
  return data.message
}

export const openaiBeta = async (prompt: object) => {
  const url = process.env.REACT_APP_PUBLIC_LAMBDA_ENDPOINT!
  const body = {
    action: 'openai',
    prompt,
  }
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await response.json()
  console.log('OpenAI Lambda response: ', data)
  return data.message
}

export const openaiImage = async (prompt: object) => {
  const url = process.env.REACT_APP_PUBLIC_LAMBDA_ENDPOINT!
  const body = {
    action: 'openai_image',
    prompt,
  }
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const data = await response.json()
  return data.message
}
