import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PersonIcon from "@mui/icons-material/Person";
import { alpha, Box, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useGeneralInfoContext } from "../GeneralInfoContext";
import { useS3Fetcher } from "../utils/fetcher";
import { getUserName, updateUserReferences } from "../utils/general";
import { calculateMultiUserStats, UserStatsResponse } from "../utils/numerical";
import {
  analyzePersonalities,
  PersonalityProfile,
} from "../utils/personalityInsights";
import type { ChatAnalysis, EmojiAnalysis } from "./personality/card_modules";
import { analyzeChat } from "./personality/card_modules";

type PersonalityStats = {
  stats: {
    intelligence: number;
    morality: number;
    wisdom: number;
    independence: number;
    influence: number;
  };
};

interface MediaItemProps {
  text: string;
  icon: React.ReactNode;
  color: string;
}

const MediaItem: React.FC<MediaItemProps> = ({ text, icon, color }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        mb: 1,
        borderRadius: theme.shape.borderRadius,
        bgcolor: alpha(color, 0.1),
        "&:hover": {
          bgcolor: alpha(color, 0.2),
          transition: "background-color 0.2s ease",
        },
      }}
    >
      {React.cloneElement(icon as React.ReactElement, { 
        sx: { mr: 1, color } 
      })}
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

const UserProfile: React.FC<{ userId: string; isLeft?: boolean }> = ({
  userId,
  isLeft,
}) => {
  const theme = useTheme();
  const { users } = useGeneralInfoContext();
  if (!users) return null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        flexDirection: isLeft ? "row" : "row-reverse",
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: theme.shape.borderRadius,
          bgcolor: isLeft
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PersonIcon sx={{ color: "white" }} />
      </Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {getUserName(users, userId)}
      </Typography>
    </Box>
  );
};

const PersonalityRadar: React.FC<{
  stats: PersonalityStats["stats"];
  color: string;
}> = ({ stats, color }) => {
  const theme = useTheme();

  const attributeDescriptions = {
    Intelligence: {
      desc: "Analytical thinking and problem-solving ability",
      scores: {
        high: "Exceptional analysis, innovative solutions, deep technical understanding",
        good: "Strong reasoning, grasps complex concepts, makes good connections",
        avg: "Basic problem-solving, follows discussions, occasional insights",
        low: "Struggles with complexity, misses key points, surface-level understanding"
      }
    },
    Morality: {
      desc: "Ethical behavior and consideration for others",
      scores: {
        high: "Consistently ethical, highly empathetic, actively helps others",
        good: "Generally ethical, shows empathy, considers others' needs",
        avg: "Basic moral awareness, occasional empathy, follows norms",
        low: "Self-centered, disregards others, manipulative behavior"
      }
    },
    Wisdom: {
      desc: "Life experience and judgment quality",
      scores: {
        high: "Deep insights, excellent advice, learns from all experiences",
        good: "Good judgment, learns from mistakes, shares helpful perspectives",
        avg: "Some life lessons, basic advice, learning from obvious mistakes",
        low: "Poor judgment, repeats mistakes, gives questionable advice"
      }
    },
    Independence: {
      desc: "Self-sufficiency and autonomous decision-making",
      scores: {
        high: "Strong boundaries, clear values, leads initiatives",
        good: "Self-directed, makes own choices, seeks input appropriately",
        avg: "Sometimes dependent, influenced by others, seeks validation",
        low: "Highly dependent, easily swayed, needs constant validation"
      }
    },
    Influence: {
      desc: "Leadership ability and impact on others",
      scores: {
        high: "Natural leader, highly persuasive, shapes group direction",
        good: "Often persuades others, contributes meaningfully, respected",
        avg: "Sometimes influential, occasional leadership, follows mostly",
        low: "Rarely influences others, passive follower, minimal impact"
      }
    }
  };

  const data = [
    { stat: "Intelligence", value: stats.intelligence },
    { stat: "Morality", value: stats.morality },
    { stat: "Wisdom", value: stats.wisdom },
    { stat: "Independence", value: stats.independence },
    { stat: "Influence", value: stats.influence },
  ];

  return (
    <Box sx={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <RadarChart data={data}>
          <PolarGrid
            gridType="polygon"
            stroke={alpha(theme.palette.text.primary, 0.1)}
          />
          <PolarAngleAxis
            dataKey="stat"
            tick={{
              fill: theme.palette.text.secondary,
              fontSize: 12,
              fontWeight: "bold",
            }}
          />
          <PolarRadiusAxis
            domain={[0, 100]}
            axisLine={false}
            tick={{ fontSize: 10 }}
            tickCount={4}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                const attr = attributeDescriptions[data.stat as keyof typeof attributeDescriptions];
                const scoreLevel = data.value >= 90 ? 'high' 
                  : data.value >= 70 ? 'good'
                  : data.value >= 40 ? 'avg'
                  : 'low';
                
                return (
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: theme.shape.borderRadius,
                      p: 1.5,
                      maxWidth: 280,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                      {data.stat}
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mb: 1 }}>
                      {attr.desc}
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      mb: 0.5,
                      color: theme.palette.primary.main,
                      fontWeight: 'bold'
                    }}>
                      Score: {data.value}/100
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      color: theme.palette.text.secondary,
                      fontSize: '0.8rem'
                    }}>
                      {attr.scores[scoreLevel]}
                    </Typography>
                  </Box>
                );
              }
              return null;
            }}
          />
          <Radar
            name="Stats"
            dataKey="value"
            stroke={color}
            fill={color}
            fillOpacity={0.15}
            strokeWidth={2}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const FlagItem: React.FC<{ text: string; isGreen?: boolean }> = ({
  text,
  isGreen = true,
}) => {
  const theme = useTheme();
  const color = isGreen ? theme.palette.success : theme.palette.error;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        mb: 1,
        borderRadius: theme.shape.borderRadius,
        bgcolor: alpha(color.light, 0.1),
        "&:hover": {
          bgcolor: alpha(color.light, 0.2),
          transition: "background-color 0.2s ease",
        },
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

type FlagType = 'green_flags' | 'red_flags';
type MediaTypeKey = 'song_recommendations' | 'gift_recommendations' | 'movie_recommendations' | 'book_recommendations';

type Analysis = {
  green_flags: string[];
  red_flags: string[];
  song_recommendations: string[];
  gift_recommendations: string[];
  movie_recommendations: string[];
  book_recommendations: string[];
};

const MediaRecommendations: React.FC = () => {
  const theme = useTheme();
  const { users, file } = useGeneralInfoContext();

  const { data: insights, isLoading, error } = useS3Fetcher<PersonalityProfile>({
    generator: analyzePersonalities,
    cachePath: "chat/:hash:/personality-insights.json",
    
  });

  const { data: cardAnalysis, isLoading: cardAnalysisLoading } =
    useS3Fetcher<ChatAnalysis>({
      generator: analyzeChat,
      cachePath: "chat/:hash:/chat-analysis.json",
    });

  const { data: numericalStats, isLoading: numericalLoading } =
    useS3Fetcher<UserStatsResponse>({
      generator: calculateMultiUserStats,
      cachePath: "chat/:hash:/numerical-stats.json",
    });

  if (isLoading || cardAnalysisLoading || numericalLoading) {
    return file ? (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        Loading media recommendations...
      </Box>
    ) : null;
  }

  if (!insights || !cardAnalysis || !numericalStats || error) {
    return null;
  }

  const scrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      borderRadius: theme.shape.borderRadius,
    },
  };

  const processedInsights = Object.fromEntries(
    Object.entries(insights).map(([person, analysis]) => [
      person,
      {
        ...analysis,
        green_flags: analysis.green_flags?.map((flag) =>
          updateUserReferences(flag, users || [])
        ),
        red_flags: analysis.red_flags?.map((flag) =>
          updateUserReferences(flag, users || [])
        ),
        song_recommendations: analysis.song_recommendations?.map((song) =>
          updateUserReferences(song, users || [])
        ),
        gift_recommendations: analysis.gift_recommendations?.map((gift) =>
          updateUserReferences(gift, users || [])
        ),
        movie_recommendations: analysis.movie_recommendations?.map((movie) =>
          updateUserReferences(movie, users || [])
        ),
        book_recommendations: analysis.book_recommendations?.map((book) =>
          updateUserReferences(book, users || [])
        ),
      },
    ])
  );

  const mediaTypes: Array<{
    key: MediaTypeKey;
    title: string;
    icon: React.ReactNode;
    color: string;
  }> = [
    {
      key: 'song_recommendations',
      title: 'Songs',
      icon: <MusicNoteIcon />,
      color: theme.palette.secondary.main
    },
    {
      key: 'gift_recommendations',
      title: 'Gifts',
      icon: <CardGiftcardIcon />,
      color: theme.palette.success.main
    },
    {
      key: 'movie_recommendations',
      title: 'Movies',
      icon: <LocalMoviesIcon />,
      color: theme.palette.warning.main
    },
    {
      key: 'book_recommendations',
      title: 'Books',
      icon: <MenuBookIcon />,
      color: theme.palette.info.main
    }
  ];

  const userStats = Object.fromEntries(
    Object.entries(numericalStats).map(([userId, stats], index) => [
      userId,
      stats
    ])
  );

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 3 }}>
        {Object.entries(processedInsights).map(([person, analysis]) => {
          const personStats = userStats[person];
          
          if (!personStats) {
            console.warn(`No numerical stats found for user: ${person}`);
            return null;
          }

          return (
            <Paper
              key={person}
              elevation={3}
              sx={{
                p: 3,
                bgcolor: theme.palette.grey[50],
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <UserProfile userId={person} isLeft />

              {person && numericalStats && Object.keys(numericalStats).length > 0 && (
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                    Personality Profile
                  </Typography>
                  <PersonalityRadar
                    stats={personStats.stats}
                    color={person === users?.[0].username
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main}
                  />
                </Box>
              )}

              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                {(['green_flags', 'red_flags'] as const).map((flagType: FlagType) => (
                  analysis[flagType] && (
                    <Box key={flagType}>
                      <Typography
                        variant="subtitle2"
                        sx={{ 
                          mb: 1, 
                          color: flagType === 'green_flags' 
                            ? theme.palette.success.main 
                            : theme.palette.error.main 
                        }}
                      >
                        {flagType === 'green_flags' ? 'Green Flags' : 'Red Flags'}
                      </Typography>
                      <Box sx={{ maxHeight: "150px", overflow: "auto", pr: 1, ...scrollbarStyles }}>
                        {analysis[flagType].map((flag: string, index: number) => (
                          <FlagItem 
                            key={index} 
                            text={flag} 
                            isGreen={flagType === 'green_flags'} 
                          />
                        ))}
                      </Box>
                    </Box>
                  )
                ))}
              </Box>

              {cardAnalysis.emojiStats.length > 0 && (
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                    Favorite Expressions
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                    {cardAnalysis.emojiStats
                      .filter((stat: EmojiAnalysis) => 
                        stat.userId === person && stat.emoji.trim()
                      )
                      .map((stat: EmojiAnalysis, index: number) => (
                        <Typography
                          key={index}
                          variant="body1"
                          sx={{
                            fontSize: "1.2rem",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <span>{stat.emoji}</span>
                          <span style={{
                            fontSize: "0.9rem",
                            color: theme.palette.text.secondary,
                          }}>
                            × {stat.count}
                          </span>
                        </Typography>
                      ))}
                  </Box>
                </Box>
              )}

              <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: "bold" }}>
                Media Recommendations
              </Typography>

              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                {mediaTypes.map(({ key, title, icon, color }) => (
                  analysis[key] && (
                    <Box key={key}>
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        {title}
                      </Typography>
                      <Box sx={{ maxHeight: "150px", overflow: "auto", pr: 1, ...scrollbarStyles }}>
                        {analysis[key].map((item: string, index: number) => (
                          <MediaItem
                            key={index}
                            text={item}
                            icon={icon}
                            color={color}
                          />
                        ))}
                      </Box>
                    </Box>
                  )
                ))}
              </Box>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default MediaRecommendations;
