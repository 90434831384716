import FavoriteIcon from '@mui/icons-material/Favorite'
import InfoIcon from '@mui/icons-material/Info'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Box,
  CircularProgress,
  List,
  Tooltip as MuiTooltip,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import React from 'react'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import {
  analyzeCompatibility,
  CompatibilityResult,
} from '../utils/compatibility'
import { useS3Fetcher } from '../utils/fetcher'
import { updateUserReferences } from '../utils/general'

const InsightItem: React.FC<{
  text: string
  color: string
}> = ({ text, color }) => {
  const theme = useTheme()

  return (
    <Typography
      variant="body2"
      sx={{
        p: 1.5,
        borderRadius: 1,
        mb: 1,
        bgcolor: alpha(theme.palette.background.paper, 0.6),
        borderLeft: `4px solid ${color}`,
        color: theme.palette.text.secondary,
        lineHeight: 1.4,
        '&:hover': {
          bgcolor: alpha(theme.palette.background.paper, 0.8),
          transition: 'background-color 0.2s ease',
        },
      }}
    >
      {text}
    </Typography>
  )
}

const getScoreColor = (score: number) => {
  if (score >= 90) return '#2ECC71' // Bright green
  if (score >= 80) return '#87D37C' // Light green
  if (score >= 70) return '#F1C40F' // Yellow
  if (score >= 60) return '#E67E22' // Orange
  return '#E74C3C' // Red
}

const CompatibilityScore: React.FC = () => {
  const { file, users } = useGeneralInfoContext()
  const theme = useTheme()
  const {
    data: compatibility,
    isLoading,
    error,
  } = useS3Fetcher<CompatibilityResult>({
    generator: analyzeCompatibility,
    cachePath: 'chat/:hash:/compatibility.json',
  })

  if (isLoading || error || !compatibility || !users) {
    if (!file) {
      return null
    }
    return isLoading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        Loading compatibility score...
      </Box>
    ) : null
  }

  // Update all text content with current user names
  const updatedStrengths = compatibility.strengths.map(strength => 
    updateUserReferences(strength, users)
  )
  const updatedChallenges = compatibility.challenges.map(challenge => 
    updateUserReferences(challenge, users)
  )
  const updatedRecommendations = compatibility.recommendations.map(recommendation => 
    updateUserReferences(recommendation, users)
  )
  const updatedOffTheRecordInsights = updateUserReferences(compatibility.offTheRecordInsights, users)

  const sections = [
    {
      icon: <FavoriteIcon sx={{ fontSize: 32 }} />,
      title: 'Relationship Strengths',
      items: updatedStrengths,
      color: '#2ECC71',
      bgColor: '#E8F5E9',
    },
    {
      icon: <WarningIcon sx={{ fontSize: 32 }} />,
      title: 'Potential Challenges',
      items: updatedChallenges,
      color: '#FF9800',
      bgColor: '#FFF3E0',
    },
    {
      icon: <LightbulbIcon sx={{ fontSize: 32 }} />,
      title: 'Growth Opportunities',
      items: updatedRecommendations,
      color: '#3498DB',
      bgColor: '#E3F2FD',
    },
  ]

  const scoreColor = getScoreColor(compatibility.overallScore)

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        mb: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              textAlign: 'center',
              mb: 4,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 2,
              }}
            >
              <Typography
                variant="h5"
                component="h2"
                sx={{ fontWeight: 'bold', mr: 1 }}
              >
                Compatibility Analysis
              </Typography>
              <MuiTooltip
                title="Looks at various aspects of the relationship to provide a compatibility score out of 100."
                arrow
              >
                <InfoIcon
                  sx={{
                    fontSize: '1rem',
                    color: 'text.secondary',
                    cursor: 'help',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                  aria-label="Engagement Chart Information"
                />
              </MuiTooltip>
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={compatibility.overallScore}
                size={160}
                thickness={6}
                sx={{
                  color: scoreColor,
                  backgroundColor: alpha(scoreColor, 0.1),
                  borderRadius: '50%',
                  boxShadow: `0 0 20px ${alpha(scoreColor, 0.2)}`,
                  transition: 'all 0.3s ease',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    color: scoreColor,
                    textShadow: `0 0 10px ${alpha(scoreColor, 0.4)}`,
                    transition: 'all 0.3s ease',
                    lineHeight: 1,
                  }}
                >
                  {compatibility.overallScore}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: '#F3E5F5' + '10', // Purple background
              border: `1px solid ${'#9C27B0'}30`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                pb: 1.5,
                borderBottom: `1px solid ${'#9C27B0'}30`,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: '#9C27B0' + '20',
                  display: 'flex',
                  mr: 2,
                }}
              >
                <InfoIcon sx={{ fontSize: 32, color: '#9C27B0' }} />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                Off the Record Insights
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                p: 1.5,
                borderRadius: 1,
                bgcolor: alpha(theme.palette.background.paper, 0.6),
                borderLeft: `4px solid #9C27B0`,
                color: theme.palette.text.secondary,
                lineHeight: 1.6,
              }}
            >
              {updatedOffTheRecordInsights}
            </Typography>
          </Paper>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
          gap: 3,
          mt: 6,
        }}
      >
        {sections.map((section) => (
          <Paper
            key={section.title}
            elevation={2}
            sx={{
              p: 2,
              borderRadius: 2,
              backgroundColor: section.bgColor + '10',
              border: `1px solid ${section.color}30`,
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                pb: 1.5,
                borderBottom: `1px solid ${section.color}30`,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: section.color + '20',
                  display: 'flex',
                  mr: 2,
                }}
              >
                {React.cloneElement(section.icon, {
                  sx: { color: section.color },
                })}
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                }}
              >
                {section.title}
              </Typography>
            </Box>
            <List sx={{ p: 0 }}>
              {section.items.map((item, index) => (
                <InsightItem key={index} text={item} color={section.color} />
              ))}
            </List>
          </Paper>
        ))}
      </Box>
    </Paper>
  )
}

export default CompatibilityScore
