import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  Box,
  IconButton,
  Paper,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { FaBars } from "react-icons/fa";
import {
  Area,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MajorEventType } from "../../../utils/sentimentAnalysis";
import { GradientTitle } from "./charts/ChronoBoard"; // You'll need to export this from TimelineView
import { getWeekStart } from "./dateUtils";
import SentimentEventsSidebar from "./SentimentEventsSidebar";
import { calculateWeightedSentiment } from "./weightedSentiment"; // Import the existing module

interface GlobalSentimentChartProps {
  sentimentData: Record<string, number>;
  majorEvents?: MajorEventType[];
  numChats: number;
  chatNames: Record<string, string>;
  chatCategories: Record<string, string>;
  chatUsers: Record<string, Array<{ username: string; name: string }>>;
  onViewModeChange?: (mode: "chart" | "timeline") => void;
  viewMode: "chart" | "timeline";
}

interface ChartDataPoint {
  weekStart: string;
  sentiment: number | null;
  timestamp: number;
}

// Add this type definition
interface TooltipProps {
  active?: boolean;
  payload?: Array<any>;
  label?: string;
  majorEvents: MajorEventType[];
  chatNames: Record<string, string>;
  chatCategories: Record<string, string>;
  chatUsers: Record<string, Array<{ username: string; name: string }>>;
  theme: any; // Using any for theme type for brevity, but you could import the proper theme type
}

// Define the getScoreColor function at the top of the file
const getScoreColor = (score: number) => {
  const intensity = Math.min(Math.abs(score) / 10, 1) * 0.15;
  return score > 0
    ? `rgba(76, 175, 80, ${intensity})` // Green
    : `rgba(239, 83, 80, ${intensity})`; // Red
};

// Update the categoryColors to use richer negative colors
const categoryColors: Record<string, { positive: string; negative: string }> = {
  friendship: {
    positive: "#B3DBFF", // Light blue
    negative: "#003399", // Rich navy blue
  },
  professional: {
    positive: "#E0E0E0", // Light gray
    negative: "#2B2B2B", // Rich dark gray
  },
  romance: {
    positive: "#FFB3B3", // Soft pastel pink
    negative: "#990000", // Rich blood red
  },
  family: {
    positive: "#B8E6B8", // Soft pastel green
    negative: "#004400", // Rich forest green
  },
  default: {
    positive: "#F5F5F5", // Light grey
    negative: "#333333", // Rich dark grey
  },
};

// Modify the getCategoryColor function to preserve rich colors
const getCategoryColor = (
  category: string,
  sentiment: number,
  majorScore: number
): string => {
  const colors = categoryColors[category] || categoryColors.default;
  const baseColor = sentiment >= 0 ? colors.positive : colors.negative;

  // Convert the majorScore to a 0-1 scale
  const intensity = Math.min(majorScore / 10, 1);

  if (sentiment >= 0) {
    // Positive sentiment logic remains the same
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    const adjustedR = Math.round(255 - (255 - r) * intensity);
    const adjustedG = Math.round(255 - (255 - g) * intensity);
    const adjustedB = Math.round(255 - (255 - b) * intensity);

    return `rgb(${adjustedR}, ${adjustedG}, ${adjustedB})`;
  } else {
    // Modified negative sentiment logic to maintain rich colors
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    // Use minimal intensity reduction to keep colors rich
    const adjustedR = Math.round(r * (1 - intensity * 0.2)); // Reduced from 0.3 to 0.2
    const adjustedG = Math.round(g * (1 - intensity * 0.2));
    const adjustedB = Math.round(b * (1 - intensity * 0.2));

    return `rgb(${adjustedR}, ${adjustedG}, ${adjustedB})`;
  }
};

// Add this helper function to determine if we should use light text
const shouldUseLightText = (
  category: string,
  sentiment: number,
  majorScore: number
): boolean => {
  // For negative sentiments with high major scores, use light text
  if (sentiment < 0 && majorScore > 5) {
    return true;
  }
  return false;
};

// Add this custom tooltip component
const CustomTooltip = ({
  active,
  payload,
  label,
  majorEvents,
  chatNames,
  chatCategories,
  chatUsers,
  theme,
}: TooltipProps) => {
  if (!active || !payload || !payload.length || !label) return null;

  try {
    const weekStart = new Date(label as string);
    if (isNaN(weekStart.getTime())) {
      console.error("Invalid date label:", label);
      return null;
    }

    const weekEnd = new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000);

    const eventsThisWeek = majorEvents
      .filter((event: MajorEventType) => {
        try {
          const eventDate = new Date(event.timestamp_range.start);
          return (
            !isNaN(eventDate.getTime()) &&
            eventDate >= weekStart &&
            eventDate < weekEnd
          );
        } catch (error) {
          console.error("Error processing event:", event);
          return false;
        }
      })
      .sort(
        (a: MajorEventType, b: MajorEventType) =>
          new Date(a.timestamp_range.start).getTime() -
          new Date(b.timestamp_range.start).getTime()
      );

    const sentimentValue = payload[0].value;

    return (
      <Paper
        elevation={3}
        sx={{
          p: 2,
          backgroundColor: "rgba(255, 255, 255, 0.98)",
          maxWidth: 350,
          borderRadius: 2,
          boxShadow: theme.shadows[3],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1.5,
            pb: 1.5,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              fontWeight: 600,
              color: theme.palette.text.primary,
            }}
          >
            {format(weekStart, "MMMM d, yyyy")}
          </Typography>

          <Typography
            sx={{
              fontSize: "1.1rem",
              fontWeight: 600,
              color: sentimentValue > 0 ? "success.main" : "error.main",
            }}
          >
            {sentimentValue > 0 ? "+" : ""}
            {sentimentValue.toFixed(2)}
          </Typography>
        </Box>

        {eventsThisWeek.length > 0 && (
          <>
            <Typography
              variant="subtitle2"
              sx={{
                mb: 1.5,
                fontSize: "0.9rem",
                fontWeight: 600,
                color: theme.palette.text.secondary,
              }}
            >
              Key Events This Week
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              {eventsThisWeek.map((event, index) => {
                const eventUsers = event.hash
                  ? chatUsers[event.hash] || []
                  : [];
                const chatName = event.hash
                  ? updateUserReferences(chatNames[event.hash], eventUsers)
                  : "Unknown Chat";
                const category = event.hash
                  ? chatCategories[event.hash]
                  : "default";
                const sentiment = event.sentiment || 0;
                const useLightText = shouldUseLightText(
                  category,
                  sentiment,
                  event.major_score
                );

                return (
                  <Box
                    key={index}
                    sx={{
                      p: 1.5,
                      borderRadius: 1.5,
                      backgroundColor: getCategoryColor(
                        category,
                        sentiment,
                        event.major_score
                      ),
                      border: `1px solid ${theme.palette.divider}`,
                      transition: "transform 0.2s ease",
                      color: useLightText ? "white" : "inherit",
                      "&:hover": {
                        transform: "translateX(4px)",
                      },
                      "& .MuiTypography-root": {
                        color: useLightText
                          ? "rgba(255, 255, 255, 0.9)"
                          : theme.palette.text.primary,
                      },
                      "& .MuiTypography-caption": {
                        color: useLightText
                          ? "rgba(255, 255, 255, 0.7)"
                          : theme.palette.text.secondary,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 0.5,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: useLightText
                            ? "rgba(255, 255, 255, 0.7)"
                            : theme.palette.text.secondary,
                        }}
                      >
                        {format(
                          new Date(event.timestamp_range.start),
                          "MMM d, yyyy"
                        )}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 600,
                          color: useLightText
                            ? "rgba(255, 255, 255, 0.7)"
                            : theme.palette.text.secondary,
                          ml: 1,
                        }}
                      >
                        Score: {event.major_score}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color: useLightText
                          ? "rgba(255, 255, 255, 0.9)"
                          : theme.palette.text.primary,
                        mb: 0.25,
                      }}
                    >
                      {updateUserReferences(event.event, eventUsers)}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        display: "block",
                        color: useLightText
                          ? "rgba(255, 255, 255, 0.7)"
                          : theme.palette.text.secondary,
                        mb: 0.5,
                      }}
                    >
                      {chatName}
                    </Typography>

                    <Typography
                      variant="caption"
                      sx={{
                        color: useLightText
                          ? "rgba(255, 255, 255, 0.7)"
                          : theme.palette.text.secondary,
                        fontSize: "0.75rem",
                        lineHeight: 1.4,
                        display: "block",
                      }}
                    >
                      {updateUserReferences(
                        event.event_deep_dive.event_summary,
                        eventUsers
                      )}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Paper>
    );
  } catch (error) {
    console.error("Error in CustomTooltip:", error, "Label:", label);
    return null;
  }
};

// Add this helper function to update user references
const updateUserReferences = (
  text: string,
  users: Array<{ username: string; name: string }>
): string => {
  // Define regex patterns for different mention styles
  const patterns = [
    /\{([^}]+)\}/g, // {username}
    /@(\w+)/g, // @username
    /\b(\w+)\b/g, // username as a standalone word
  ];

  let updatedText = text;

  patterns.forEach((pattern) => {
    updatedText = updatedText.replace(pattern, (match, p1) => {
      const user = users.find(
        (u) => u.username.toLowerCase() === p1.toLowerCase()
      );
      return user ? user.name : match;
    });
  });

  return updatedText;
};

const GlobalSentimentChart: React.FC<GlobalSentimentChartProps> = ({
  sentimentData,
  majorEvents = [],
  numChats,
  chatNames,
  chatCategories,
  chatUsers,
  onViewModeChange,
  viewMode,
}) => {
  // console.log('Major Events received:', majorEvents)
  // console.log('Sentiment Data received:', sentimentData)
  // console.log('Number of chats:', numChats)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dateRange, setDateRange] = React.useState<[number, number]>([0, 100]);
  const [isEventsPanelOpen, setIsEventsPanelOpen] = useState<boolean>(false);

  // Move weightedSentimentData calculation before it's used
  const transformedMajorEvents = useMemo(() => {
    // Add debug logging
    console.log("Original major events:", majorEvents);
    const filteredEvents = majorEvents.filter(
      (event) => event.major_score >= 8
    );
    console.log("Filtered major events:", filteredEvents);
    return {
      global: majorEvents,
    };
  }, [majorEvents]);

  const weightedSentimentData = useMemo(() => {
    return calculateWeightedSentiment(
      { global: sentimentData },
      transformedMajorEvents
    );
  }, [sentimentData, transformedMajorEvents]);

  // Now we can use weightedSentimentData in chartData
  const chartData: ChartDataPoint[] = useMemo(() => {
    // First standardize all dates to week starts
    const uniqueWeekData = new Map<string, number>();

    Object.entries(weightedSentimentData).forEach(([date, sentiment]) => {
      const weekStart = getWeekStart(date);
      uniqueWeekData.set(weekStart, sentiment);
    });

    return Array.from(uniqueWeekData.entries())
      .map(([weekStart, sentiment]) => ({
        weekStart,
        sentiment,
        timestamp: new Date(weekStart).getTime(),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
  }, [weightedSentimentData]);

  // Insert nulls for gaps larger than two weeks to create discontinuities
  const processedChartData: ChartDataPoint[] = useMemo(() => {
    const MAX_GAP = 14 * 24 * 60 * 60 * 1000; // Two weeks in milliseconds
    const newData: ChartDataPoint[] = [];

    for (let i = 0; i < chartData.length; i++) {
      const currentPoint = chartData[i];
      newData.push(currentPoint);

      if (i < chartData.length - 1) {
        const nextPoint = chartData[i + 1];
        const gap = nextPoint.timestamp - currentPoint.timestamp;

        if (gap > MAX_GAP) {
          newData.push({
            weekStart: new Date(
              currentPoint.timestamp + MAX_GAP / 2
            ).toISOString(),
            sentiment: null,
            timestamp: currentPoint.timestamp + MAX_GAP / 2,
          });
        }
      }
    }

    return newData;
  }, [chartData]);

  // Get the actual data points (excluding nulls) for date range calculations
  const dateRangeData = useMemo(() => {
    return Object.entries(sentimentData)
      .map(([weekStart, _]) => ({
        timestamp: new Date(weekStart).getTime(),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
  }, [sentimentData]);

  const minDate = dateRangeData.length > 0 ? dateRangeData[0].timestamp : 0;
  const maxDate =
    dateRangeData.length > 0
      ? dateRangeData[dateRangeData.length - 1].timestamp
      : 0;

  // Filter the chart data based on the date range
  const filteredChartData = useMemo(() => {
    return processedChartData.filter((data) => {
      const percentage =
        ((data.timestamp - minDate) / (maxDate - minDate)) * 100;
      return percentage >= dateRange[0] && percentage <= dateRange[1];
    });
  }, [processedChartData, dateRange, minDate, maxDate]);

  const handleDateRangeChange = (event: Event, newValue: number | number[]) => {
    setDateRange(newValue as [number, number]);
  };

  const valuetext = (value: number) => {
    if (dateRangeData.length === 0) return "";
    try {
      const timestamp = minDate + ((maxDate - minDate) * value) / 100;
      return format(new Date(timestamp), "MMM d, yyyy");
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        bgcolor: theme.palette.grey[50],
        borderRadius: 2,
        height: theme.spacing(50),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Add container for title and toggle button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          position: "relative",
          px: 2,
        }}
      >
        <IconButton
          onClick={() =>
            onViewModeChange?.(viewMode === "chart" ? "timeline" : "chart")
          }
          size="small"
          sx={{
            position: "absolute",
            left: 8,
            top: 0,
            bgcolor: theme.palette.background.paper,
            "&:hover": {
              bgcolor: theme.palette.background.default,
            },
          }}
        >
          <ShowChartIcon />
        </IconButton>

        <GradientTitle
          variant="h4"
          sx={{
            fontSize: "1.7rem",
            mb: 1,
            width: "100%",
            textAlign: "center",
          }}
        >
          Global SentimentBoard
        </GradientTitle>

        <IconButton
          onClick={() => setIsEventsPanelOpen(!isEventsPanelOpen)}
          size="small"
          sx={{
            position: "absolute",
            right: 8,
            top: 0,
            bgcolor: theme.palette.background.paper,
            "&:hover": {
              bgcolor: theme.palette.background.default,
            },
          }}
        >
          <FaBars />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          gap: 2,
          height: "calc(100% - 60px)", // Adjust for header
        }}
      >
        {/* Chart Section - Adjust width based on panel state */}
        <Box
          sx={{
            flexGrow: 1,
            width: isEventsPanelOpen ? "70%" : "100%",
            transition: "width 0.3s ease",
          }}
        >
          <ResponsiveContainer width="100%" height="85%">
            <LineChart
              data={filteredChartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <defs>
                <linearGradient
                  id="sentimentGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#4CAF50" stopOpacity={0.15} />
                  <stop offset="50%" stopColor="#FFFFFF" stopOpacity={0.05} />
                  <stop offset="100%" stopColor="#EF5350" stopOpacity={0.15} />
                </linearGradient>
                <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#4CAF50" />
                  <stop offset="50%" stopColor="#2196F3" />
                  <stop offset="100%" stopColor="#EF5350" />
                </linearGradient>
              </defs>

              <XAxis
                dataKey="weekStart"
                tickFormatter={(tickItem) =>
                  tickItem ? format(new Date(tickItem), "MMM d") : ""
                }
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                axisLine={{ stroke: theme.palette.divider }}
                tickLine={{ stroke: theme.palette.divider }}
              />

              <YAxis
                domain={[-10, 10]}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                tickFormatter={(value) => `${value > 0 ? "+" : ""}${value}`}
                axisLine={false}
                tickLine={false}
                ticks={[-10, -5, 0, 5, 10]}
              >
                <Label
                  value="Sentiment"
                  position="insideLeft"
                  angle={-90}
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.secondary,
                  }}
                />
              </YAxis>

              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    label={label}
                    majorEvents={majorEvents}
                    chatNames={chatNames}
                    chatCategories={chatCategories}
                    chatUsers={chatUsers}
                    theme={theme}
                  />
                )}
                wrapperStyle={{ zIndex: 9999 }}
              />

              {/* Remove these reference lines */}
              {/* <ReferenceLine y={5} stroke={theme.palette.divider} strokeDasharray="3 3" />
              <ReferenceLine y={-5} stroke={theme.palette.divider} strokeDasharray="3 3" /> */}

              {/* Keep only the center line at y=0 */}
              <ReferenceLine
                y={0}
                stroke={theme.palette.divider}
                strokeWidth={1}
              />

              {/* Add gradient background */}
              <Area
                type="monotone"
                dataKey="sentiment"
                stroke="none"
                fill="url(#sentimentGradient)"
                fillOpacity={1}
              />

              {/* Main line with gradient */}
              <Line
                type="monotone"
                dataKey="sentiment"
                stroke="url(#lineGradient)"
                strokeWidth={2.5}
                dot={false}
                activeDot={{
                  r: 6,
                  stroke: theme.palette.background.paper,
                  strokeWidth: 2,
                  fill: theme.palette.primary.main,
                }}
                connectNulls={false}
              />
            </LineChart>
          </ResponsiveContainer>

          <Box sx={{ px: 3, pt: 2, mt: -2 }}>
            <Slider
              value={dateRange}
              onChange={handleDateRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              getAriaValueText={valuetext}
              sx={{
                "& .MuiSlider-valueLabel": {
                  fontSize: "0.75rem",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                color: theme.palette.text.secondary,
                fontSize: "0.75rem",
              }}
            >
              <span>{valuetext(dateRange[0])}</span>
              <span>{valuetext(dateRange[1])}</span>
            </Box>
          </Box>
        </Box>

        {/* Replace the old sidebar with the new component */}
        <SentimentEventsSidebar
          isOpen={isEventsPanelOpen}
          events={majorEvents}
          chatNames={chatNames}
          chatCategories={chatCategories}
          dateRange={dateRange}
          minDate={minDate}
          maxDate={maxDate}
          chatUsers={chatUsers}
        />
      </Box>
    </Paper>
  );
};

export default GlobalSentimentChart;
