import { z } from 'zod'
import { zodResponseFormat } from 'openai/helpers/zod'
import { config } from '../config'
import { GoogleGenerativeAI, SchemaType } from "@google/generative-ai"
import { ChatMessage } from './types'
import { requestFile } from './s3cache'
import { checkFileExists, uploadJsonToS3 } from './s3Storage'
import { openaiBeta } from './llmClients'

// Define the schema first
const compatibilitySchema = {
  type: SchemaType.OBJECT,
  properties: {
    categoryScores: {
      type: SchemaType.OBJECT,
      properties: {
        'Shared Interests': { type: SchemaType.NUMBER },
        'Conversational Balance': { type: SchemaType.NUMBER },
        'Emotional Tone Match': { type: SchemaType.NUMBER },
        'Engagement Level': { type: SchemaType.NUMBER },
        'Conflict and Resolution': { type: SchemaType.NUMBER },
        'Humor and Playfulness': { type: SchemaType.NUMBER },
        'Supportiveness': { type: SchemaType.NUMBER },
      },
      required: ['Shared Interests', 'Conversational Balance', 'Emotional Tone Match', 
                'Engagement Level', 'Conflict and Resolution', 'Humor and Playfulness', 
                'Supportiveness'],
    },
    strengths: {
      type: SchemaType.ARRAY,
      items: { type: SchemaType.STRING },
    },
    challenges: {
      type: SchemaType.ARRAY,
      items: { type: SchemaType.STRING },
    },
    recommendations: {
      type: SchemaType.ARRAY,
      items: { type: SchemaType.STRING },
    },
    reasoning: { type: SchemaType.STRING },
    scoreJustification: { type: SchemaType.STRING },
    offTheRecordInsights: { type: SchemaType.STRING },
    result: { type: SchemaType.NUMBER },
  },
  required: ['categoryScores', 'strengths', 'challenges', 'recommendations', 
            'reasoning', 'scoreJustification', 'offTheRecordInsights', 'result'],
};

// Then initialize the AI client
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY || "");
const model = genAI.getGenerativeModel({ 
  model: config.textModelGoogle,
  generationConfig: {
    temperature: 0,
    responseMimeType: "application/json",
    responseSchema: compatibilitySchema,
  }
});

// Define response schema
const CompatibilityResponse = z.object({
  categoryScores: z.object({
    'Shared Interests': z.number(),
    'Conversational Balance': z.number(),
    'Emotional Tone Match': z.number(),
    'Engagement Level': z.number(),
    'Conflict and Resolution': z.number(),
    'Humor and Playfulness': z.number(),
    Supportiveness: z.number(),
  }),
  reasoning: z.string(),
  strengths: z.array(z.string()),
  challenges: z.array(z.string()),
  recommendations: z.array(z.string()),
  scoreJustification: z.string(),
  offTheRecordInsights: z.string(),
  result: z.number(),
})

type CompatibilityResponseType = z.infer<typeof CompatibilityResponse>

export interface CompatibilityResult {
  overallScore: number
  strengths: string[]
  challenges: string[]
  recommendations: string[]
  detailedReasoning: string
  chunkCount: number
  scoreJustification: string
  categoryScores: {
    'Shared Interests': number
    'Conversational Balance': number
    'Emotional Tone Match': number
    'Engagement Level': number
    'Conflict and Resolution': number
    'Humor and Playfulness': number
    Supportiveness: number
  }
  offTheRecordInsights: string
}

// Define the PreviousAnalysis interface
interface PreviousAnalysis {
  allStrengths: string[]
  allChallenges: string[]
  allRecommendations: string[]
  allCategoryScores: Array<{
    'Shared Interests': number
    'Conversational Balance': number
    'Emotional Tone Match': number
    'Engagement Level': number
    'Conflict and Resolution': number
    'Humor and Playfulness': number
    Supportiveness: number
  }>
  allOffTheRecordInsights: string[]
  chunkNumber: number
}

// First, let's create a default compatibility result for when analysis fails
const DEFAULT_COMPATIBILITY_RESULT: CompatibilityResult = {
  overallScore: 0,
  strengths: [],
  challenges: [],
  recommendations: [],
  detailedReasoning: '',
  chunkCount: 0,
  scoreJustification: '',
  categoryScores: {
    'Shared Interests': 0,
    'Conversational Balance': 0,
    'Emotional Tone Match': 0,
    'Engagement Level': 0,
    'Conflict and Resolution': 0,
    'Humor and Playfulness': 0,
    Supportiveness: 0,
  },
  offTheRecordInsights: '',
}

export const analyzeCompatibility = async (
  messages: ChatMessage[]
): Promise<CompatibilityResult> => {
  console.log('Starting compatibility analysis with messages:', {
    messageCount: messages.length,
    firstDate: messages[0]?.date,
    lastDate: messages[messages.length - 1]?.date,
  })

  const users = Array.from(new Set(messages.map((m) => m.user))).sort()
  const conversationText = messages
    .map((m) => `${m.user}: ${m.message}`)
    .join('\n')

  // Create the prompt once to reuse
  const prompt = `Conversation to analyze between ${users.join(' and ')}:
${conversationText}

1. Shared Interests (20 points)
- High (16-20): Multiple deep discussions on shared topics
- Medium (11-15): Some shared interests with moderate engagement
- Low (0-10): Few/superficial shared interests
Look for: Topic depth, mutual enthusiasm, follow-up discussions

2. Conversational Balance (15 points)
- High (12-15): Equal participation and natural flow
- Medium (8-11): Slightly uneven but collaborative
- Low (0-7): Significantly one-sided
Look for: Message ratio, initiative sharing, response patterns

3. Emotional Tone Match (15 points)
- High (12-15): Consistent emotional alignment
- Medium (8-11): Generally aligned with occasional mismatches
- Low (0-7): Frequent emotional disconnects
Look for: Similar expression style, matching enthusiasm, emotional understanding

4. Engagement Level (15 points)
- High (12-15): Consistent deep engagement
- Medium (8-11): Regular engagement with some depth
- Low (0-7): Superficial engagement
Look for: Follow-up questions, detailed responses, conversation continuity

5. Conflict and Resolution (10 points)
- High (8-10): Constructive handling of differences
- Medium (5-7): Some tension but manageable
- Low (0-4): Poor conflict management
Look for: Respectful disagreement, compromise, recovery from misunderstandings

6. Humor and Playfulness (10 points)
- High (8-10): Regular shared humor
- Medium (5-7): Occasional humor
- Low (0-4): Little/mismatched humor
Look for: Mutual jokes, playful exchanges, shared references

7. Supportiveness (15 points)
- High (12-15): Consistent, genuine support
- Medium (8-11): Moderate support
- Low (0-7): Limited support
Look for: Validation, encouragement, follow-up on important matters

Red Flags (subtract 5 points each):
- Dismissive responses
- Consistent emotional misalignment
- Pattern of ignored messages
- Unresolved conflicts
- Manipulation/passive-aggression

Remember to:
- Cite specific examples/quotes
- Focus on patterns over isolated instances
- Consider both content and communication style
- Note reciprocity in all categories

Provide your analysis in JSON format:
{
  "categoryScores": {
    "Shared Interests": <score/20>,
    "Conversational Balance": <score/15>,
    "Emotional Tone Match": <score/15>,
    "Engagement Level": <score/15>,
    "Conflict and Resolution": <score/10>,
    "Humor and Playfulness": <score/10>,
    "Supportiveness": <score/15>
  },
  "strengths": ["In depth Paragraph going through a strength with examples.", "..."],
  "challenges": ["In depth Paragraph going through a challenge with examples.", "..."],
  "recommendations": ["In depth Paragraph going through a recommendation with examples.", "..."],
  "reasoning": "Brief analysis with 2-3 conversation examples",
  "scoreJustification": "Score explanation with examples",
  "offTheRecordInsights": "Not displayed to the user; what's really going on here? Any undertones/things you suspect but can't be 100% sure of? Concerns or hopes? Are they family members? Anything that didn't fit into another category. Format as a single string with at most 7 sentences.",
  "result": <total/100>
}`

  try {
    // Try Google API first
    try {
      const geminiResponse = await model.generateContent({
        contents: [{ role: "user", parts: [{ text: prompt }]}],
      });

      const response = await geminiResponse.response;
      const text = response.text();
      
      try {
        const parsed = CompatibilityResponse.parse(JSON.parse(text));
        const compatibilityResult: CompatibilityResult = {
          overallScore: parsed.result,
          strengths: parsed.strengths,
          challenges: parsed.challenges,
          recommendations: parsed.recommendations,
          detailedReasoning: parsed.reasoning,
          chunkCount: 1,
          scoreJustification: parsed.scoreJustification,
          categoryScores: parsed.categoryScores,
          offTheRecordInsights: parsed.offTheRecordInsights,
        }

        await uploadJsonToS3(`chat/:hash:/compatibility.json`, compatibilityResult)
        return compatibilityResult;
      } catch (parseError) {
        console.error('Error parsing Gemini response, falling back to OpenAI:', parseError)
        throw parseError; // Throw to trigger OpenAI fallback
      }
    } catch (geminiError) {
      console.error('Gemini API error, falling back to OpenAI:', geminiError)
      
      // Fall back to OpenAI using the correct format
      const openaiResponse = await openaiBeta({
        model: config.textModel,
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that analyzes chat compatibility.',
          },
          { role: 'user', content: prompt }
        ],
        response_format: zodResponseFormat(CompatibilityResponse, 'compatibilityResponse'),
        temperature: 0,
      });

      const parsed = CompatibilityResponse.parse(
        openaiResponse.choices[0]?.message?.parsed
      );
      if (!parsed) {
        throw new Error('Unexpected response from OpenAI API');
      }

      const compatibilityResult: CompatibilityResult = {
        overallScore: parsed.result,
        strengths: parsed.strengths,
        challenges: parsed.challenges,
        recommendations: parsed.recommendations,
        detailedReasoning: parsed.reasoning,
        chunkCount: 1,
        scoreJustification: parsed.scoreJustification,
        categoryScores: parsed.categoryScores,
        offTheRecordInsights: parsed.offTheRecordInsights,
      }

      await uploadJsonToS3(`chat/:hash:/compatibility.json`, compatibilityResult)
      return compatibilityResult;
    }
  } catch (error) {
    console.error('All LLM attempts failed:', error)
    return DEFAULT_COMPATIBILITY_RESULT
  }
}

// Add custom loader/saver functions for useS3Fetcher
export const compatibilityLoader = async (
  path: string,
  hash: string,
  token: string,
  refreshToken: () => Promise<string>
) => {
  const desiredFilePath = path.replace(':hash:', hash)
  const exists = await checkFileExists(desiredFilePath)
  if (!exists) {
    return null
  }
  const data = await requestFile(desiredFilePath, hash, token, refreshToken)
  return JSON.parse(data)
}

export const compatibilitySaver = async (
  path: string,
  hash: string,
  result: CompatibilityResult
) => {
  const desiredFilePath = path.replace(':hash:', hash)
  await uploadJsonToS3(desiredFilePath, result)
}
