import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import { alpha } from "@mui/material/styles";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { MajorEventType } from "../../../utils/sentimentAnalysis";

// Import the helper functions from GlobalSentimentChart
const categoryColors: Record<string, { positive: string; negative: string }> = {
  friendship: {
    positive: "#B3DBFF", // Light blue
    negative: "#003399", // Rich navy blue
  },
  professional: {
    positive: "#E0E0E0", // Light gray
    negative: "#2B2B2B", // Rich dark gray
  },
  romance: {
    positive: "#FFB3B3", // Soft pastel pink
    negative: "#990000", // Rich blood red
  },
  family: {
    positive: "#B8E6B8", // Soft pastel green
    negative: "#004400", // Rich forest green
  },
  default: {
    positive: "#F5F5F5", // Light grey
    negative: "#333333", // Rich dark grey
  },
};

const getCategoryColor = (
  category: string,
  sentiment: number,
  majorScore: number
): string => {
  const colors = categoryColors[category] || categoryColors.default;
  const baseColor = sentiment >= 0 ? colors.positive : colors.negative;

  const intensity = Math.min(majorScore / 10, 1);

  if (sentiment >= 0) {
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    const adjustedR = Math.round(255 - (255 - r) * intensity);
    const adjustedG = Math.round(255 - (255 - g) * intensity);
    const adjustedB = Math.round(255 - (255 - b) * intensity);

    return `rgb(${adjustedR}, ${adjustedG}, ${adjustedB})`;
  } else {
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    const adjustedR = Math.round(r * (1 - intensity * 0.2));
    const adjustedG = Math.round(g * (1 - intensity * 0.2));
    const adjustedB = Math.round(b * (1 - intensity * 0.2));

    return `rgb(${adjustedR}, ${adjustedG}, ${adjustedB})`;
  }
};

const shouldUseLightText = (
  category: string,
  sentiment: number,
  majorScore: number
): boolean => {
  if (sentiment < 0 && majorScore > 5) {
    return true;
  }
  return false;
};

interface SentimentEventsSidebarProps {
  isOpen: boolean;
  events: MajorEventType[];
  chatNames: Record<string, string>;
  chatCategories: Record<string, string>;
  dateRange: [number, number];
  minDate: number;
  maxDate: number;
  chatUsers: Record<string, Array<{ username: string; name: string }>>;
}

const updateUserReferences = (
  text: string,
  users: Array<{ username: string; name: string }>
): string => {
  // Define regex patterns for different mention styles
  const patterns = [
    /\{([^}]+)\}/g, // {username}
    /@(\w+)/g, // @username
    /\b(\w+)\b/g, // username as a standalone word
  ];

  let updatedText = text;

  patterns.forEach((pattern) => {
    updatedText = updatedText.replace(pattern, (match, p1) => {
      const user = users.find(
        (u: { username: string; name: string }) =>
          u.username.toLowerCase() === p1.toLowerCase()
      );
      return user ? user.name : match;
    });
  });

  return updatedText;
};

const SentimentEventsSidebar: React.FC<SentimentEventsSidebarProps> = ({
  isOpen,
  events,
  chatNames,
  chatCategories,
  dateRange,
  minDate,
  maxDate,
  chatUsers,
}) => {
  const theme = useTheme();
  const [selectedEvent, setSelectedEvent] = useState<MajorEventType | null>(
    null
  );
  const [showScrollTop, setShowScrollTop] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  let scrollTimeout: NodeJS.Timeout;
  const [listScrollPosition, setListScrollPosition] = useState(0);

  // Handle scroll to show/hide scroll-to-top button
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setShowScrollTop(scrollTop > 200);

    // Set scrolling state to true and clear any existing timeout
    setIsScrolling(true);
    clearTimeout(scrollTimeout);

    // Set a timeout to set scrolling to false after 2 seconds of no scrolling
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);
  };

  // Scroll to top function
  const scrollToTop = () => {
    containerRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Filter and sort events
  const visibleEvents = events.filter((event) => {
    const eventDate = new Date(event.timestamp_range.start).getTime();
    const percentage = ((eventDate - minDate) / (maxDate - minDate)) * 100;
    return percentage >= dateRange[0] && percentage <= dateRange[1];
  });

  const sortedEvents = [...visibleEvents].sort(
    (a, b) =>
      new Date(a.timestamp_range.start).getTime() -
      new Date(b.timestamp_range.start).getTime()
  );

  const handleEventClick = (event: MajorEventType) => {
    if (containerRef.current) {
      setListScrollPosition(containerRef.current.scrollTop);
    }
    setSelectedEvent(event);
  };

  const handleBackClick = () => {
    setSelectedEvent(null);
    // Use setTimeout to restore scroll position after render
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = listScrollPosition;
      }
    }, 0);
  };

  const renderEventList = () => (
    <Box sx={{ height: "100%", overflow: "auto", px: 2 }}>
      {sortedEvents.map((event, index) => {
        // Safely access chatUsers with event.hash
        const eventUsers =
          event.hash && chatUsers[event.hash] ? chatUsers[event.hash] : [];
        const chatName = event.hash
          ? updateUserReferences(chatNames[event.hash], eventUsers)
          : "Unknown Chat";
        const category = event.hash ? chatCategories[event.hash] : "default";
        const sentiment = event.sentiment || 0;
        const useLightText = shouldUseLightText(
          category,
          sentiment,
          event.major_score
        );

        return (
          <Box
            key={index}
            onClick={() => handleEventClick(event)}
            sx={{
              mb: 1.5,
              p: 1.5,
              borderRadius: 2,
              backgroundColor: getCategoryColor(
                category,
                sentiment,
                event.major_score
              ),
              boxShadow: 1,
              border: `1px solid ${theme.palette.divider}`,
              transition: "transform 0.4s ease",
              cursor: "pointer",
              color: useLightText ? "white" : "inherit",
              "&:hover": {
                transform: "translateX(5px)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0.25,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: useLightText
                    ? "rgba(255, 255, 255, 0.7)"
                    : theme.palette.text.secondary,
                }}
              >
                {format(new Date(event.timestamp_range.start), "MMMM d, yyyy")}
              </Typography>

              <Typography
                sx={{
                  color: useLightText
                    ? "white"
                    : sentiment > 0
                    ? "success.main"
                    : "error.main",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {sentiment > 0 ? "+" : ""}
                {sentiment.toFixed(2)}
              </Typography>
            </Box>

            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
                mb: 0.25,
              }}
            >
              {updateUserReferences(event.event || "", eventUsers)}
            </Typography>

            <Typography
              variant="caption"
              sx={{
                display: "block",
                mb: 0.5,
              }}
            >
              {chatName}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                lineHeight: 1.5,
              }}
            >
              {updateUserReferences(
                event.event_deep_dive?.event_summary || "",
                eventUsers
              )}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  const renderEventDetail = () => {
    if (!selectedEvent) return null;

    const category = selectedEvent.hash
      ? chatCategories[selectedEvent.hash]
      : "default";
    const sentiment = selectedEvent.sentiment || 0;
    const majorScore = selectedEvent.major_score || 0;
    const useLightText = shouldUseLightText(category, sentiment, majorScore);

    // Retrieve users specific to the selected event's chat
    const relevantUsers = selectedEvent.hash
      ? chatUsers[selectedEvent.hash] || []
      : [];

    return (
      <Box
        ref={containerRef}
        sx={{ height: "100%", overflow: "auto", px: 2, position: "relative" }}
        onScroll={handleScroll}
      >
        <Fade in={showScrollTop && isScrolling}>
          <IconButton
            onClick={scrollToTop}
            onMouseEnter={() => setIsScrolling(true)}
            sx={{
              position: "sticky",
              left: "50%",
              transform: "translateX(-50%)",
              top: 20,
              bgcolor: theme.palette.background.paper,
              boxShadow: 2,
              "&:hover": {
                bgcolor: theme.palette.background.default,
              },
              zIndex: 3,
            }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        </Fade>

        {/* Modified Top Bar with Back Button and Event Summary Card */}
        <Box
          sx={{
            pt: 2,
            pb: 1,
            zIndex: 2,
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <IconButton
            onClick={handleBackClick}
            sx={{ color: theme.palette.text.secondary }}
          >
            <ArrowBackIcon />
          </IconButton>

          {/* Event Summary Card with smaller font sizes */}
          <Box
            sx={{
              flex: 1,
              p: 2,
              mb: 1,
              borderRadius: 2,
              backgroundColor: getCategoryColor(
                category,
                sentiment,
                majorScore
              ),
              boxShadow: 1,
              color: useLightText ? "white" : "inherit",
              "& .MuiTypography-root": {
                color: useLightText ? "rgba(255, 255, 255, 0.9)" : "inherit",
              },
              "& .MuiTypography-caption": {
                color: useLightText ? "rgba(255, 255, 255, 0.7)" : "inherit",
              },
            }}
          >
            <Typography variant="caption" sx={{ display: "block", mb: 0.5 }}>
              {format(
                new Date(selectedEvent.timestamp_range.start),
                "MMMM d, yyyy"
              )}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
              {updateUserReferences(selectedEvent.event || "", relevantUsers)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 800,
                  color: useLightText ? "white !important" : "inherit",
                }}
              >
                Importance: {majorScore}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 800,
                  color: useLightText ? "white !important" : "inherit",
                }}
              >
                Sentiment: {sentiment > 0 ? "+" : ""}
                {sentiment.toFixed(1)}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Analysis Sections */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 4 }}>
          {/* Summary Section */}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              Event Summary
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
              {updateUserReferences(
                selectedEvent.event_deep_dive?.event_summary || "",
                relevantUsers
              )}
            </Typography>
          </Box>

          {/* Key Interactions */}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              Key Interactions
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
              {updateUserReferences(
                selectedEvent.event_deep_dive?.key_interactions || "",
                relevantUsers
              )}
            </Typography>
          </Box>

          {/* Emotional Impact */}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              Emotional Impact
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
              {updateUserReferences(
                selectedEvent.event_deep_dive?.emotional_responses || "",
                relevantUsers
              )}
            </Typography>
          </Box>

          {/* Impact on Relationship */}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              Impact on Relationship
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
              {updateUserReferences(
                selectedEvent.event_deep_dive?.potential_impact || "",
                relevantUsers
              )}
            </Typography>
          </Box>

          {/* Psychological Analysis */}
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              Psychological Analysis
            </Typography>
            <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
              {updateUserReferences(
                selectedEvent.event_deep_dive?.psychoanalytical_takeaway || "",
                relevantUsers
              )}
            </Typography>
          </Box>
        </Box>

        {/* Messages Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Related Messages
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {selectedEvent.eventMessages?.map((message, index) => {
              const baseColor = selectedEvent.hash
                ? chatUsers[selectedEvent.hash].find(
                    (u: { username: string; name: string }) =>
                      u.username === message.user
                  )
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
                : theme.palette.grey[500];

              const backgroundColor = message.isContext
                ? alpha(baseColor, message.isAfterContext ? 0.2 : 0.1)
                : baseColor;

              // Retrieve display name from relevantUsers
              const user = selectedEvent.hash
                ? chatUsers[selectedEvent.hash].find(
                    (u: { username: string; name: string }) =>
                      u.username === message.user
                  )
                : undefined;
              const displayName = user ? user.name : message.user;

              return (
                <Box
                  key={index}
                  sx={{
                    backgroundColor,
                    p: 1.5,
                    mb: 1,
                    borderRadius: 1,
                    color: message.isContext ? "inherit" : "white",
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 0.5,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: !user ? theme.palette.error.main : "inherit", // Highlight if mapping fails
                      }}
                    >
                      {displayName}
                    </span>
                    <span
                      style={{
                        fontSize: "0.8em",
                        color: message.isContext
                          ? theme.palette.text.secondary
                          : "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {format(
                        new Date(message.timestamp),
                        "MMM d, yyyy h:mm a"
                      )}
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                  >
                    {updateUserReferences(
                      message.message,
                      selectedEvent.hash
                        ? chatUsers[selectedEvent.hash] || []
                        : []
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* ... rest of the analysis code ... */}
      </Box>
    );
  };

  // Clean up the timeout when component unmounts
  useEffect(() => {
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, []);

  // Add this useEffect near the top of the component
  useEffect(() => {
    if (selectedEvent) {
      // Use setTimeout to ensure the detail view has rendered
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = 0;
        }
      }, 0);
    }
  }, [selectedEvent]);

  return (
    <Box
      sx={{
        width: isOpen ? "30%" : "0%",
        overflow: "hidden",
        transition: "width 0.3s ease",
        borderLeft: isOpen ? `1px solid ${theme.palette.divider}` : "none",
        height: "100%",
      }}
    >
      {isOpen &&
        (selectedEvent ? (
          <Fade in={true} timeout={200}>
            <div style={{ height: "100%" }}>{renderEventDetail()}</div>
          </Fade>
        ) : (
          renderEventList()
        ))}
    </Box>
  );
};

export default SentimentEventsSidebar;
