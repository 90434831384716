import { alpha, Box, Tooltip, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { ProcessedEvent, TimelineLayoutProps } from "./TimelineTypes";

const categoryStyles: Record<string, { color: string; label: string }> = {
  friendship: { color: "#4287f5", label: "Friend" },
  professional: { color: "#3d3d3d", label: "Professional" },
  romance: { color: "#e91e63", label: "Romance" },
  family: { color: "#4caf50", label: "Family" },
  default: { color: "#9e9e9e", label: "General" },
};

interface CategoryStat {
  category: string;
  label: string;
  color: string;
  count: number;
  avgSentiment: number;
  percentage: number;
}

const HorizontalTimelineView: React.FC<TimelineLayoutProps> = ({
  events,
  chatCategories,
  chatNames,
  chatUsers,
  minDate,
  maxDate,
  dateRange = [0, 100],
}) => {
  const theme = useTheme();
  const EVENT_SIZE_BASE = 32;

  const processedEvents = useMemo(() => {
    return [...events]
      .sort(
        (a, b) =>
          new Date(a.timestamp_range.start).getTime() -
          new Date(b.timestamp_range.start).getTime()
      )
      .map((event) => {
        const eventDate = new Date(event.timestamp_range.start).getTime();
        const position = Math.min(
          Math.max(
            5, // Minimum 5% from left
            ((eventDate - minDate) / (maxDate - minDate)) * 90 + 5 // Scale to 90% of space and offset by 5%
          ),
          95 // Maximum 95% from left
        );
        const size = Math.max(
          EVENT_SIZE_BASE * 0.5,
          Math.min(EVENT_SIZE_BASE, event.major_score * EVENT_SIZE_BASE * 0.4)
        );
        const category = event.hash ? chatCategories[event.hash] : "default";
        const eventUsers = event.hash ? chatUsers[event.hash] || [] : [];
        const chatName = event.hash ? chatNames[event.hash] : "Unknown Chat";

        return {
          ...event,
          size,
          category,
          eventUsers,
          chatName,
          date: eventDate,
          position,
        } as ProcessedEvent;
      })
      .filter((event) => {
        const percentage = ((event.date - minDate) / (maxDate - minDate)) * 100;
        return percentage >= dateRange[0] && percentage <= dateRange[1];
      });
  }, [
    events,
    minDate,
    maxDate,
    chatCategories,
    chatUsers,
    chatNames,
    dateRange,
  ]);

  const categoryStats = useMemo<CategoryStat[]>(() => {
    if (!processedEvents.length) return [];

    return Object.entries(categoryStyles)
      .map(([category, style]) => {
        const categoryEvents = processedEvents.filter(
          (e) => e.category === category
        );
        const avgSentiment =
          categoryEvents.reduce((sum, e) => sum + (e.sentiment ?? 0), 0) /
          (categoryEvents.length || 1);

        return {
          category,
          label: style.label,
          color: style.color,
          count: categoryEvents.length,
          avgSentiment,
          percentage: (categoryEvents.length / processedEvents.length) * 100,
        };
      })
      .filter((stat) => stat.count > 0)
      .sort((a, b) => b.count - a.count);
  }, [processedEvents]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        px: 4,
        py: 2,
      }}
    >
      {/* Category Stats */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
          mb: 4,
          px: 4,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        {categoryStats.map((stat) => (
          <Box
            key={stat.category}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              minWidth: 120,
              p: 1.5,
              borderRadius: 2,
              bgcolor: alpha(stat.color, 0.08),
              "&:hover": {
                bgcolor: alpha(stat.color, 0.12),
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: stat.color,
                  mr: 1,
                }}
              />
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {stat.label}
              </Typography>
            </Box>

            <Box sx={{ display: "grid", gap: 0.5, width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="caption" color="text.secondary">
                  Events
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  {stat.count}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="caption" color="text.secondary">
                  % of Total
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  {stat.percentage.toFixed(1)}%
                </Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="caption" color="text.secondary">
                  Avg Sentiment
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 600,
                    color:
                      stat.avgSentiment >= 0
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                  }}
                >
                  {stat.avgSentiment > 0 ? "+" : ""}
                  {stat.avgSentiment.toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                height: 2,
                width: `${stat.percentage}%`,
                bgcolor: stat.color,
                borderRadius: 1,
                transition: "width 0.3s ease",
              }}
            />
          </Box>
        ))}
      </Box>

      {/* Timeline Container */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: 160,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Timeline base line */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "5%",
            right: "5%",
            height: 2,
            bgcolor: alpha(theme.palette.divider, 0.3),
            transform: "translateY(-50%)",
          }}
        />

        {/* Events */}
        {processedEvents.map((event, index) => {
          const categoryStyle = categoryStyles[event.category];
          const sentimentColor =
            (event.sentiment ?? 0) >= 0
              ? theme.palette.success.main
              : theme.palette.error.main;
          const sentimentIntensity = Math.min(
            Math.abs(event.sentiment ?? 0) * 0.7,
            0.9
          );

          return (
            <Tooltip
              key={index}
              title={
                <Box>
                  <Typography variant="body2" fontWeight="bold">
                    {format(event.date, "MMM d, yyyy")}
                  </Typography>
                  <Typography variant="body2" sx={{ maxWidth: 300, my: 1 }}>
                    {event.event}
                  </Typography>
                  <Typography variant="body2" color={sentimentColor}>
                    Sentiment: {event.sentiment?.toFixed(1)}
                  </Typography>
                </Box>
              }
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: `${event.position}%`,
                  transform: "translate(-50%, -50%)",
                  width: event.size,
                  height: event.size,
                  cursor: "pointer",
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    inset: 0,
                    borderRadius: "50%",
                    border: `3px solid ${categoryStyle.color}`,
                    opacity: 0.8,
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "15%",
                    left: "15%",
                    right: "15%",
                    bottom: "15%",
                    borderRadius: "50%",
                    bgcolor: alpha(sentimentColor, sentimentIntensity),
                    boxShadow: `0 0 ${event.size / 4}px ${alpha(
                      sentimentColor,
                      0.3
                    )}`,
                    transition: "all 0.2s ease",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    inset: 0,
                    borderRadius: "50%",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                  }}
                />
              </Box>
            </Tooltip>
          );
        })}

        {/* Date markers */}
        {[0, 0.25, 0.5, 0.75, 1].map((fraction) => {
          const timestamp = minDate + (maxDate - minDate) * fraction;
          const dateVal = new Date(timestamp);

          if (isNaN(dateVal.getTime())) {
            return null;
          }

          return (
            <Typography
              key={fraction}
              variant="caption"
              sx={{
                position: "absolute",
                top: "50%",
                left: `${5 + fraction * 90}%`,
                transform: "translate(-50%, 24px)",
                color: theme.palette.text.secondary,
                fontSize: "0.75rem",
                whiteSpace: "nowrap",
                bgcolor: alpha(theme.palette.background.paper, 0.8),
                px: 1,
                borderRadius: 0.5,
              }}
            >
              {format(dateVal, "MMM yyyy")}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default HorizontalTimelineView;
