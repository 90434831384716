import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Tooltip as MuiTooltip,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGeneralInfoContext } from "../GeneralInfoContext";
import {
  EngagementData,
  MessageDistribution,
  processEngagementData,
} from "../utils/engagementLogic";
import { useS3Fetcher } from "../utils/fetcher";
import { ChatUser } from "../utils/types";
import UserNameAndIdentityDialog from "./UserDashboard/components/UserNameAndIdentityDialog";
import Heatmap from "./engagement/heatmap";

const MessageDistributionBar: React.FC<{
  distribution: MessageDistribution[];
  onNameClick: () => void;
}> = ({ distribution, onNameClick }) => {
  const theme = useTheme();
  const { users } = useGeneralInfoContext();
  const [user1, user2] = distribution;

  if (!user1 || !user2) return null;

  const getDisplayName = (userId: string) =>
    users?.find((u) => u.username === userId)?.name || userId;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        width: "100%",
        mb: 2,
      }}
    >
      <UserProfile
        userId={user1.userId}
        displayName={getDisplayName(user1.userId)}
        isLeft
        onNameClick={onNameClick}
      />

      <Box
        sx={{
          flex: 1,
          height: "24px",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: 1,
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: `${user1.percentage}%`,
            bgcolor: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "0.75rem",
            fontWeight: "bold",
            transition: "width 0.3s ease",
          }}
        >
          {`${Math.round(user1.percentage)}%`}
        </Box>
        <Box
          sx={{
            width: `${user2.percentage}%`,
            bgcolor: theme.palette.secondary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontSize: "0.75rem",
            fontWeight: "bold",
            transition: "width 0.3s ease",
          }}
        >
          {`${Math.round(user2.percentage)}%`}
        </Box>
      </Box>

      <UserProfile
        userId={user2.userId}
        displayName={getDisplayName(user2.userId)}
        onNameClick={onNameClick}
      />
    </Box>
  );
};

const UserProfile: React.FC<{
  userId: string;
  displayName: string;
  isLeft?: boolean;
  onNameClick: () => void;
}> = ({ userId, displayName, isLeft, onNameClick }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        flexDirection: isLeft ? "row" : "row-reverse",
        cursor: "pointer",
      }}
      onClick={onNameClick}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: "50%",
          bgcolor: isLeft
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PersonIcon sx={{ color: "white" }} />
      </Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {displayName}
      </Typography>
    </Box>
  );
};

const formatXAxis = (dateStr: string) => {
  return format(new Date(dateStr), "MMM d");
};

const formatTooltipDate = (dateStr: string) => {
  return format(new Date(dateStr), "MMM d, yyyy");
};

const Engagement: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { users, setUsers } = useGeneralInfoContext();
  const [isNameEditModalOpen, setIsNameEditModalOpen] = useState(false);

  const { data: engagementData } = useS3Fetcher<EngagementData>({
    generator: processEngagementData,
    cachePath: "chat/:hash:/engagement.json",
  });

  if (!engagementData || !users) {
    return (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="body1">Loading chart data...</Typography>
      </Box>
    );
  }

  const { chartData } = engagementData;
  const [user1, user2] = users;

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
            position: "relative",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Engagement
          </Typography>
          <MuiTooltip
            title="This dashboard shows your conversation patterns over time. The percentage bar displays the overall message distribution between participants. The heatmap reveals your most active conversation times throughout the week. The line graph tracks word count over time, helping identify periods of high engagement and conversation flow. Click names to customize display settings."
            arrow
          >
            <InfoIcon
              sx={{
                fontSize: "1rem",
                color: "text.secondary",
                cursor: "help",
                ml: 1,
                "&:hover": {
                  color: "primary.main",
                },
              }}
              aria-label="Engagement Dashboard Information"
            />
          </MuiTooltip>
        </Box>

        {engagementData.distribution && (
          <Box sx={{ mb: 1 }}>
            <MessageDistributionBar
              distribution={[
                engagementData.distribution.find(
                  (d) => d.userId === user1.username
                )!,
                engagementData.distribution.find(
                  (d) => d.userId === user2.username
                )!,
              ]}
              onNameClick={() => setIsNameEditModalOpen(true)}
            />
          </Box>
        )}

        {engagementData.heatmapData && (
          <Box sx={{ mb: 1 }}>
            <Heatmap data={engagementData.heatmapData} />
          </Box>
        )}

        <Box sx={{ height: theme.spacing(54) }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <XAxis
                dataKey="weekStart"
                tickFormatter={formatXAxis}
                interval={(isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                }}
                labelFormatter={formatTooltipDate}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey={(data) => data.numWords[user1.username]}
                name={user1.name}
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey={(data) => data.numWords[user2.username]}
                name={user2.name}
                stroke={theme.palette.secondary.main}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <UserNameAndIdentityDialog
        open={isNameEditModalOpen}
        onClose={() => setIsNameEditModalOpen(false)}
        users={users || []}
        onNameChange={(updatedUsers: ChatUser[]) => {
          setUsers(updatedUsers);
          setIsNameEditModalOpen(false);
        }}
      />
    </Box>
  );
};

export default Engagement;
