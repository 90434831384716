import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
  GetIdCommand,
} from '@aws-sdk/client-cognito-identity'
import {
  ListObjectsV2Command,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import axios from 'axios'

const REGION = 'us-east-1'
const IDENTITY_POOL_ID = 'us-east-1:58a613d6-6782-4e96-8122-8052d0bd8733'
const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET
const BAD_BUCKET_NAME = process.env.REACT_APP_BAD_S3_BUCKET

const getS3Client = async () => {
  try {
    const cognitoClient = new CognitoIdentityClient({ region: REGION })
    const { IdentityId } = await cognitoClient.send(
      new GetIdCommand({ IdentityPoolId: IDENTITY_POOL_ID })
    )

    if (!IdentityId) {
      throw new Error('Failed to get IdentityId')
    }

    const { Credentials } = await cognitoClient.send(
      new GetCredentialsForIdentityCommand({ IdentityId })
    )

    if (!Credentials) {
      throw new Error('Failed to get Credentials')
    }

    return new S3Client({
      region: REGION,
      credentials: {
        accessKeyId: Credentials.AccessKeyId!,
        secretAccessKey: Credentials.SecretKey!,
        sessionToken: Credentials.SessionToken,
      },
    })
  } catch (error) {
    console.error('Error creating S3 client:', error)
    if (error instanceof Error) {
      console.error('Error name:', error.name)
      console.error('Error message:', error.message)
    }
    throw error
  }
}

export const checkFileExists = async (prefix: string): Promise<boolean> => {
  try {
    const client = await getS3Client()
    const command = new ListObjectsV2Command({
      Bucket: BUCKET_NAME,
      Prefix: prefix,
      MaxKeys: 1,
    })
    const response = await client.send(command)

    if (response.Contents && response.Contents.length > 0) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error checking if file exists:', error)
    throw error
  }
}

// Add this new type for the progress callback
type ProgressCallback = (progress: number) => void

export const uploadToS3 = async (
  file: File,
  hash: string,
  bad = false,
  onProgress?: ProgressCallback
): Promise<string> => {
  if (process.env.TESTING === '1') {
    return Promise.resolve(hash)
  }

  const fileExists = await checkFileExists(hash)
  console.log('fileExists', fileExists)
  if (fileExists) {
    return hash
  }

  try {
    const client = await getS3Client()
    const upload = new Upload({
      client,
      params: {
        Bucket: bad ? BAD_BUCKET_NAME : BUCKET_NAME,
        Key: hash,
        Body: file,
        ContentType: file.type,
        Metadata: {
          'original-filename': file.name,
        },
      },
    })

    upload.on('httpUploadProgress', (progress) => {
      console.log('upload progress', progress)
      if (onProgress && progress.loaded && progress.total) {
        const percentProgress = (progress.loaded / progress.total) * 100
        onProgress(percentProgress)
      }
    })

    await upload.done()
    return hash
  } catch (err) {
    console.error('Error uploading file to S3:', err)
    throw err
  }
}

export const uploadJsonToS3 = async (
  key: string,
  data: Record<string, any>,
  maxRetries: number = 3
): Promise<void> => {
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const client = await getS3Client()
      const command = new PutObjectCommand({
        Bucket: BUCKET_NAME,
        Key: key,
        Body: JSON.stringify(data),
        ContentType: 'application/json',
      })

      const response = await client.send(command)
      console.log('Uploaded JSON to S3:', response)
      return // Success - exit the function
    } catch (error) {
      console.error(`Attempt ${attempt + 1}/${maxRetries} failed:`, error)

      if (attempt === maxRetries - 1) {
        // If this was the last attempt, throw the error
        throw error
      }

      // Calculate delay with exponential backoff (1s, 2s, 4s, ...)
      const delay = Math.pow(2, attempt) * 1000
      console.log(`Retrying in ${delay}ms...`)
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
  }
}

// Test function to check Cognito credentials
export const testCognitoCredentials = async () => {
  try {
    const client = new CognitoIdentityClient({ region: REGION })

    const { IdentityId } = await client.send(
      new GetIdCommand({ IdentityPoolId: IDENTITY_POOL_ID })
    )

    if (!IdentityId) {
      throw new Error('Failed to get IdentityId')
    }

    return IdentityId
  } catch (error) {
    console.error('Error obtaining Cognito Identity:', error)
    if (error instanceof Error) {
      console.error('Error name:', error.name)
      console.error('Error message:', error.message)
    }
    throw error
  }
}

export const uploadUrlToS3 = async (
  url: string,
  s3Path: string,
  downsize: boolean = false
) => {
  const request = {
    action: 'url',
    url,
    s3Path,
    downsize,
  }
  const response = await axios.post(
    process.env.REACT_APP_PUBLIC_LAMBDA_ENDPOINT!,
    request
  )
  console.log('Upload URL response:', response)
  if (!response.data) {
    throw new Error('No response from Lambda')
  }
}

export const uploadBlobToS3 = async (
  blob: Blob,
  s3Path: string,
  maxRetries: number = 3
): Promise<void> => {
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const client = await getS3Client()
      const command = new PutObjectCommand({
        Bucket: BUCKET_NAME,
        Key: s3Path,
        Body: blob,
        ContentType: 'audio/mp3',
      })

      const response = await client.send(command)
      console.log('Uploaded blob to S3:', response)
      return // Success - exit the function
    } catch (error) {
      console.error(`Attempt ${attempt + 1}/${maxRetries} failed:`, error)

      if (attempt === maxRetries - 1) {
        // If this was the last attempt, throw the error
        throw error
      }

      // Calculate delay with exponential backoff (1s, 2s, 4s, ...)
      const delay = Math.pow(2, attempt) * 1000
      console.log(`Retrying in ${delay}ms...`)
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
  }
}
