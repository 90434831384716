import { Box, Typography } from "@mui/material";
import React from "react";
import VideoContainer from "./VideoContainer";

interface RightSideContentProps {
  selectedFileType: string | null;
  showTitle?: boolean;
}

const RightSideContent: React.FC<RightSideContentProps> = ({
  selectedFileType,
  showTitle = true,
}) => {
  const getVideoSource = () => {
    switch (selectedFileType) {
      case "WhatsApp (Phone)":
        return "/video/whatsapp_phone_upload.mp4";
      case "WhatsApp (Laptop)":
        return "/video/whatsapp_laptop_upload.mp4";
      case "iMessage (Mac)":
        return "/video/imessage_upload.mp4";
      case "Instagram":
        return "/video/instagram_upload.mp4";
      case "Facebook":
        return "/video/facebook_upload.mp4";
      case "Telegram":
        return "/video/telegram_upload.mp4";
      case "Android":
        return "/video/android_upload.mp4";
      default:
        return null;
    }
  };

  const getVideoTitle = () => {
    if (!selectedFileType) return "";
    return `How to upload your ${selectedFileType} Chat`;
  };

  if (!selectedFileType) return null;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showTitle && (
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {getVideoTitle()}
        </Typography>
      )}
      <VideoContainer
        selectedFileType={selectedFileType}
      />
    </Box>
  );
};

export default RightSideContent;
