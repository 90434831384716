import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tooltip as MuiTooltip,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getWeekStart } from '../components/UserDashboard/components/dateUtils'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { getUserName, updateUserReferences } from '../utils/general'
import { getToken } from '../utils/hashAuthentication'
import { requestFile } from '../utils/s3cache'
import { checkFileExists } from '../utils/s3Storage'
import { fetchSentiments } from '../utils/sentimentAnalysis'
import { ChatMessage, ChatUser, SentimentData } from '../utils/types'

// Export the CustomTooltip component

type CustomTooltipProps = {
  users: ChatUser[]
  active?: boolean
  payload?: any
  label?: string
  coordinate?: any
}
export const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  users,
}: CustomTooltipProps) => {
  const theme = useTheme()
  const initialYPosition = useRef(coordinate.y - 100)
  const tooltipRef = useRef<HTMLDivElement>(null)

  if (active && payload && payload.length) {
    const data = payload[0].payload
    const tooltipWidth = 500

    const screenMidpoint = window.innerWidth / 2
    const isPastMidpoint = coordinate.x > screenMidpoint
    const xPosition = isPastMidpoint
      ? coordinate.x - tooltipWidth - 10
      : coordinate.x + 10
    const yPosition = initialYPosition.current

    return (
      <Paper
        ref={tooltipRef}
        sx={{
          p: theme.spacing(2),
          width: tooltipWidth,
          position: 'absolute',
          top: yPosition,
          left: xPosition,
          pointerEvents: 'none',
          zIndex: 1000,
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
          {`Week of ${format(new Date(label || ''), 'MMM d, yyyy')}`}
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Summary Quote
        </Typography>
        {data.summary_quote ? (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 'bold',
                color:
                  data.summary_quote.user === users[0].username
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                mb: 1,
              }}
            >
              {getUserName(users, data.summary_quote.user)}
            </Typography>
            <Box
              sx={{
                bgcolor:
                  data.summary_quote.user === users[0].username
                    ? theme.palette.primary.light
                    : theme.palette.secondary.light,
                p: 1,
                borderRadius: theme.shape.borderRadius / 2,
                fontSize: '0.8rem',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              {data.summary_quote.quote}
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" sx={{ fontSize: '0.8rem', mb: 2 }}>
            No summary quote available
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1, pr: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Salient Events:
            </Typography>
            <List dense sx={{ fontSize: '0.8rem' }}>
              {data.salient_events
                .sort((a: any, b: any) => b.salience - a.salience)
                .map((event: any, index: number) => {
                  const updatedEvent = updateUserReferences(event.event, users);
                  
                  return (
                    <ListItem key={index} sx={{ p: 0 }}>
                      <ListItemText primary={updatedEvent} />
                    </ListItem>
                  );
                })}
            </List>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Top Quotes:
            </Typography>
            {data.top_quotes.map((quote: any, index: number) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    color:
                      quote.user === users[0].username
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main,
                    mb: 1,
                  }}
                >
                  {getUserName(users, quote.user)}
                </Typography>
                <Box
                  sx={{
                    bgcolor:
                      quote.user === users[0].username
                        ? theme.palette.primary.light
                        : theme.palette.secondary.light,
                    p: 1,
                    borderRadius: theme.shape.borderRadius / 2,
                    fontSize: '0.8rem',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                  }}
                >
                  {quote.quote}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    )
  }
  return null
}

const SentimentChart: React.FC<{
  onClick?: () => void
  onDataFetched?: (data: SentimentData) => void
}> = ({ onClick, onDataFetched }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [chartData, setChartData] = useState<SentimentData>({
    sentiments: [],
    allMajorEvents: [],
  })
  const { parsedData, hash, token, users, file } = useGeneralInfoContext()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const refreshToken = async () => {
    if (!hash || !file) {
      return ''
    }
    const token = await getToken(hash, file, 3600)
    return token
  }

  const weeklyData = useMemo(() => {
    const data: { [key: string]: ChatMessage[] } = {}
    const persons: Record<string, number> = {}

    parsedData?.forEach((message) => {
      const weekStart = getWeekStart(message.date)
      if (!data[weekStart]) {
        data[weekStart] = []
      }
      data[weekStart].push(message)
      if (!persons[message.user]) {
        persons[message.user] = 1
      } else {
        persons[message.user]++
      }
    })

    const top_two = Object.keys(persons)
      .sort((a, b) => persons[b] - persons[a])
      .slice(0, 2)

    return { data, persons: Array.from(top_two).sort() }
  }, [parsedData])

  useEffect(() => {
    const loadSentiments = async () => {
      if (!hash || !token) return
      setLoading(true)
      setError(null)

      try {
        const desiredFilePath = `chat/${hash}/sentiment.json`
        const exists = await checkFileExists(desiredFilePath)
        let data: SentimentData

        if (exists) {
          data = await requestFile(desiredFilePath, hash, token, refreshToken)
        } else {
          data = await fetchSentiments(parsedData || [], weeklyData, hash)
        }

        setChartData(data)
        if (onDataFetched) onDataFetched(data)
      } catch (err) {
        setError('Failed to fetch sentiment data')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    loadSentiments()
  }, [hash, token, weeklyData, onDataFetched, users])

  if (loading || !users) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">Loading sentiment data...</Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">Error: {error}</Typography>
      </Box>
    )
  }

  if (chartData.sentiments.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1">
          No data available for the chart.
        </Typography>
      </Box>
    )
  }

  const [user1, user2] = users || []

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 2,
          boxShadow: `
            0 0 5px 2px rgba(255, 0, 0, 0.4),
            0 0 10px 4px rgba(255, 0, 0, 0.3),
            0 0 15px 6px rgba(255, 0, 0, 0.2)
          `,
          filter: 'blur(3px)',
          zIndex: 0,
        }}
      />
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: theme.palette.grey[50],
          borderRadius: 2,
          mb: 4,
          height: theme.spacing(55),
          display: 'flex',
          flexDirection: 'column',
          cursor: onClick ? 'zoom-in' : 'default',
          '&:hover': onClick ? {
            boxShadow: theme.shadows[6],
            '&::after': {
              content: '"Click to view detailed analytics"',
              position: 'absolute',
              bottom: '8px',
              right: '8px',
              padding: '4px 8px',
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              fontSize: '0.75rem',
            }
          } : {},
          position: 'relative',
          zIndex: 1,
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: 'bold', mr: 1 }}
          >
            Sentiment StoryBoard
          </Typography>
          <MuiTooltip
            title="This graph displays each person's emotional journey, with events being marked on a scale from -10 (extremely negative) to 10 (extremely positive). High points represent joy and contentment, while low points indicate tension or difficult periods. Sharp drops may signify a sudden conflict or negative events. Click on the chart to view more in depth analytics."
            arrow
          >
            <InfoIcon
              sx={{
                fontSize: '1rem',
                color: 'text.secondary',
                cursor: 'help',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              aria-label="Mood Storyline Information"
            />
          </MuiTooltip>
        </Box>

        <Box sx={{ flexGrow: 1, width: '100%', height: '100%' }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData.sentiments}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <XAxis
                dataKey="weekStart"
                tickFormatter={(tickItem) =>
                  format(new Date(tickItem), 'MMM d, yyyy')
                }
                interval={
                  (isMobile ? 2 : 1) *
                  Math.ceil(chartData.sentiments.length / 10)
                }
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <YAxis
                domain={[-10, 10]}
                tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip users={users || []} />} />
              <Legend
                payload={[
                  {
                    value: user1.name,
                    type: 'line',
                    color: theme.palette.primary.main,
                  },
                  {
                    value: user2.name,
                    type: 'line',
                    color: theme.palette.secondary.main,
                  },
                ]}
              />
              <Line
                type="monotone"
                dataKey="X_sentiment"
                name={user1.name}
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="Z_sentiment"
                name={user2.name}
                stroke={theme.palette.secondary.main}
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  )
}

export default SentimentChart
