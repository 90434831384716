import { zodResponseFormat } from "openai/helpers/zod";
import { z } from "zod";
import { config } from "../config";
import { openaiBeta } from "./llmClients";
import { uploadJsonToS3 } from "./s3Storage";
import { ChatMessage } from "./types";

interface PersonalityResponse {
  green_flags: string[];
  red_flags: string[];
  song_recommendations: string[];
  gift_recommendations: string[];
  movie_recommendations: string[];
  book_recommendations: string[];
}

export interface DualPersonalityResponse {
  user1: {
    username: string;
  } & PersonalityResponse;
  user2: {
    username: string;
  } & PersonalityResponse;
}

export interface PersonalityProfile {
  [key: string]: PersonalityResponse;
}

// Zod schema matches the TypeScript interface
const DualPersonalityResponseSchema = z.object({
  user1: z.object({
    username: z.string(),
    green_flags: z.array(z.string()),
    red_flags: z.array(z.string()),
    song_recommendations: z.array(z.string()),
    gift_recommendations: z.array(z.string()),
    movie_recommendations: z.array(z.string()),
    book_recommendations: z.array(z.string()),
  }),
  user2: z.object({
    username: z.string(),
    green_flags: z.array(z.string()),
    red_flags: z.array(z.string()),
    song_recommendations: z.array(z.string()),
    gift_recommendations: z.array(z.string()),
    movie_recommendations: z.array(z.string()),
    book_recommendations: z.array(z.string()),
  }),
});

// Add NormalizedSet class
class NormalizedSet {
  private items = new Set<string>();
  private originalCase = new Map<string, string>(); // Store original text

  add(item: string) {
    // Normalize for comparison only, but store original
    const normalized = item.toLowerCase().trim();
    this.items.add(normalized);
    this.originalCase.set(normalized, item.trim()); // Keep original case
  }

  toArray(): string[] {
    return Array.from(this.items).map(
      (item) => this.originalCase.get(item) || item
    );
  }
}

const createMessageChunks = (
  messages: ChatMessage[],
  maxTokens: number = 40000
): string[] => {
  const messageStrings = messages.map((msg) => `${msg.user}: ${msg.message}`);
  const totalTokens = messageStrings.join("\n").length / 2;

  if (totalTokens <= maxTokens) {
    return [messageStrings.join("\n")];
  }

  const chunks: string[] = [];
  let currentChunk = "";
  let currentTokens = 0;

  for (const message of messageStrings) {
    const messageTokens = (message.length + 1) / 4; // +1 for newline
    if (currentTokens + messageTokens >= maxTokens) {
      chunks.push(currentChunk.trim());
      currentChunk = message + "\n";
      currentTokens = messageTokens;
    } else {
      currentChunk += message + "\n";
      currentTokens += messageTokens;
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
};

const analyzeConversation = async (
  messages: ChatMessage[]
): Promise<DualPersonalityResponse> => {
  const userCounts = messages.reduce((acc, msg) => {
    acc[msg.user] = (acc[msg.user] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  const topUsers = Object.keys(userCounts)
    .sort((a, b) => userCounts[b] - userCounts[a])
    .slice(0, 2)
    .sort();

  const messageChunks = createMessageChunks(messages);

  const chunkPromises = messageChunks.map(async (chunk, i) => {
    const prompt = `Conversation to analyze:
${chunk}

Analyze this conversation between two people and give them recommendations. 
Note that user_1 corresponds to ${topUsers[0]} and user_2 corresponds to ${topUsers[1]}. 

When making recommendations:
- Each song/gift/movie suggestion must be unique and different from any previous recommendations
- Avoid generic suggestions
- Recommend things that align with their preferences, but which they would not already know about themselves! 

Additionally, based on their interests, hobbies, and preferences shown in the messages:
1. Provide more than 3 song recommendations that match their taste
2. Suggest more than 7 thoughtful gift ideas that align with their interests
- Recommend some gifts based on explicitly mentioned interests or needs, however feel free to also suggest ones that the person'd likely enjoy based on the their personality
- Include a brief justification for each gift based on chat evidence
- Be specific rather than generic (e.g. "A vintage Star Wars poster from Episode IV" rather than just "Star Wars merchandise")
- Be as creative as possible. As you have a minimum of 7 outputs for this category, at least some of the recommendations should be very unique
3. Recommend more than 3 movies they might enjoy 
4. Recommend more than 3 books they might enjoy 

Remember, all recommendations should be things that the users don't already have or know about! Otherwise why would they need a recommendation?

Finally, if and only if you find clear evidence in the chat, provide specific examples of "green flags" or "red flags" displayed by the users. This is completely optional - only include flags if you can point to concrete examples from the conversation. Some examples of what to look for:

Green flags (only include with specific examples):
- Shows balanced conversation by asking thoughtful questions and sharing experiences
- Demonstrates respect for boundaries and time 
- Shows consistency in behavior and communication over time
- References previous conversations showing they pay attention
- Handles misunderstandings maturely with clear communication

Red flags (only include with specific examples):
- Pushes boundaries after being told no 
- Shows excessive intensity or love bombing too early
- Tells inconsistent or contradictory stories
- Uses guilt or manipulation when not getting desired responses
- Shows pattern of one-sided conversations or minimal engagement

Remember: Only include flags if you can quote or reference specific examples from the chat. This field can be empty if no clear evidence is found.

Format your response as a JSON object with the following structure:
{
  "user1": {
    "username": "actual_username",
    "green_flags": [
      "Specific positive trait with specific example from chat"
    ],
    "red_flags": [
      "Specific concerning behavior with specific example from chat"
    ],
    "song_recommendations": [
      "Song Title - Artist"
    ],
    "gift_recommendations": [
      "Specific gift idea - Brief justification based on chat evidence"
    ],
    "movie_recommendations": [
      "Movie Title - Director"
    ],
    "book_recommendations": [
      "Book Title - Author"
    ]
  },
  "user2": {
    "username": "actual_username",
    "green_flags": [
      "Specific positive trait with specific example from chat"
    ],
    "red_flags": [
      "Specific concerning behavior with specific example from chat"
    ],
    "song_recommendations": [
      "Song Title - Artist"
    ],
    "gift_recommendations": [
      "Specific gift idea - Brief justification based on chat evidence"
    ],
    "movie_recommendations": [
      "Movie Title - Director"
    ],
    "book_recommendations": [
      "Book Title - Author"
    ]
  }
}

${chunk}`;

    try {
      const completion = await openaiBeta({
        model: config.textModel,
        messages: [
          {
            role: "system",
            content: `You are a clever analyzer of conversations, able to pick out patterns and inconsistencies from chats and return multiple high-quality and nuanced insights. Note that user_1 is ${topUsers[0]} and user_2 is ${topUsers[1]}.`,
          },
          { role: "user", content: prompt },
        ],
        temperature: 0.5,
        max_tokens: 4096,
        response_format: zodResponseFormat(
          DualPersonalityResponseSchema,
          "personalityResponse"
        ),
      });

      return DualPersonalityResponseSchema.parse(
        completion.choices[0].message.parsed
      );
    } catch (error) {
      return null;
    }
  });

  const chunkResults = (await Promise.all(chunkPromises)).filter(
    (result): result is DualPersonalityResponse => result !== null
  );

  const greenFlagsSet1 = new NormalizedSet();
  const redFlagsSet1 = new NormalizedSet();
  const songsSet1 = new NormalizedSet();
  const giftsSet1 = new NormalizedSet();
  const moviesSet1 = new NormalizedSet();
  const booksSet1 = new NormalizedSet();
  const greenFlagsSet2 = new NormalizedSet();
  const redFlagsSet2 = new NormalizedSet();
  const songsSet2 = new NormalizedSet();
  const giftsSet2 = new NormalizedSet();
  const moviesSet2 = new NormalizedSet();
  const booksSet2 = new NormalizedSet();

  chunkResults.forEach((result) => {
    result.user1.green_flags.forEach((flag: string) =>
      greenFlagsSet1.add(flag)
    );
    result.user1.red_flags.forEach((flag: string) => redFlagsSet1.add(flag));
    result.user1.song_recommendations.forEach((song: string) =>
      songsSet1.add(song)
    );
    result.user1.gift_recommendations.forEach((gift: string) =>
      giftsSet1.add(gift)
    );
    result.user1.movie_recommendations.forEach((movie: string) =>
      moviesSet1.add(movie)
    );
    result.user1.book_recommendations.forEach((book: string) =>
      booksSet1.add(book)
    );

    result.user2.green_flags.forEach((flag: string) =>
      greenFlagsSet2.add(flag)
    );
    result.user2.red_flags.forEach((flag: string) => redFlagsSet2.add(flag));
    result.user2.song_recommendations.forEach((song: string) =>
      songsSet2.add(song)
    );
    result.user2.gift_recommendations.forEach((gift: string) =>
      giftsSet2.add(gift)
    );
    result.user2.movie_recommendations.forEach((movie: string) =>
      moviesSet2.add(movie)
    );
    result.user2.book_recommendations.forEach((book: string) =>
      booksSet2.add(book)
    );
  });

  const combinedResults: DualPersonalityResponse = {
    user1: {
      username: topUsers[0],
      green_flags: greenFlagsSet1.toArray(),
      red_flags: redFlagsSet1.toArray(),
      song_recommendations: songsSet1.toArray(),
      gift_recommendations: giftsSet1.toArray(),
      movie_recommendations: moviesSet1.toArray(),
      book_recommendations: booksSet1.toArray(),
    },
    user2: {
      username: topUsers[1],
      green_flags: greenFlagsSet2.toArray(),
      red_flags: redFlagsSet2.toArray(),
      song_recommendations: songsSet2.toArray(),
      gift_recommendations: giftsSet2.toArray(),
      movie_recommendations: moviesSet2.toArray(),
      book_recommendations: booksSet2.toArray(),
    },
  };

  return combinedResults;
};

export const analyzePersonalities = async (
  messages: ChatMessage[]
): Promise<PersonalityProfile> => {
  const results = await analyzeConversation(messages);

  const profile = {
    [results.user1.username]: {
      green_flags: results.user1.green_flags,
      red_flags: results.user1.red_flags,
      song_recommendations: results.user1.song_recommendations,
      gift_recommendations: results.user1.gift_recommendations,
      movie_recommendations: results.user1.movie_recommendations,
      book_recommendations: results.user1.book_recommendations,
    },
    [results.user2.username]: {
      green_flags: results.user2.green_flags,
      red_flags: results.user2.red_flags,
      song_recommendations: results.user2.song_recommendations,
      gift_recommendations: results.user2.gift_recommendations,
      movie_recommendations: results.user2.movie_recommendations,
      book_recommendations: results.user2.book_recommendations,
    },
  };

  await uploadJsonToS3(`chat/:hash:/personality-insights.json`, profile);

  return profile;
};
