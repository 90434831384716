import { Box, LinearProgress } from '@mui/material'
import { fetchAuthSession } from 'aws-amplify/auth'
import JSZip from 'jszip'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { getToken, hashFile, signFile } from '../utils/hashAuthentication'
import { parseIMessage } from '../utils/imessage'
import { deleteFileWithPresignedUrl } from '../utils/s3cache'
import { uploadToS3 } from '../utils/s3Storage'
import { parse, sanitizeFilename } from '../utils/universal'
import { ZipFileDialog } from './ZipFileDialog'

export interface ZipFile {
  name: string
  preview: string
  date: Date
}

export interface WithFileUploadAndParseProps {
  onFileUpload: (file: File) => void
  isLoading: boolean
  error: string | null
  zipFiles: ZipFile[]
  isZipDialogOpen: boolean
  handleZipFileSelection: (fileName: string) => void
  setIsZipDialogOpen: (isOpen: boolean) => void
  fileInputRef: React.RefObject<HTMLInputElement>
  uploadProgress: number
}

export const withFileUploadAndParse = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithFileUploadAndParseProps>
) => {
  return (props: P & { onUploadSuccess?: (chatId: string) => void }) => {
    const navigate = useNavigate()
    const [state, setState] = useState({
      error: null as string | null,
      isProcessing: false,
      uploadProgress: 0,
      isZipDialogOpen: false,
    })
    const [zipState, setZipState] = useState({
      files: [] as ZipFile[],
      content: null as JSZip | null,
    })
    const { setParsedData, setToken, setHash, setFile } =
      useGeneralInfoContext()
    const fileInputRef = useRef<HTMLInputElement>(null)

    const updateProgress = (progress: number) => {
      setState((prev) => ({ ...prev, uploadProgress: Math.max(progress, 5) }))
    }

    const uploadProcess = async (file: File) => {
      const { tokens } = await fetchAuthSession()
      const fileHash = await hashFile(file)
      setHash(fileHash)
      setFile(file)

      await uploadToS3(file, fileHash, false, updateProgress)
      const refreshToken = () => getToken(fileHash, file, 3600)
      const token = await refreshToken()
      setToken(token)

      if (tokens) {
        await signFile(fileHash, file)
      }

      navigate('/main')
      props.onUploadSuccess?.(fileHash)
    }

    const extractTextFromZip = async (file: File) => {
      const zip = new JSZip()
      const contents = await zip.loadAsync(file)
      setZipState((prev) => ({ ...prev, content: contents }))

      const txtFiles = Object.keys(contents.files).filter((name) =>
        name.endsWith('.txt')
      )
      if (!txtFiles.length)
        throw new Error('No text file found in the zip archive')

      const filesPreviews = (
        await Promise.all(
          txtFiles.map(async (fileName) => {
            const textContent = await contents.file(fileName)!.async('string')
            if (!textContent || textContent.length < 3000) return null

            const isImessage = textContent.includes(': not_me:')
            const preview = isImessage
              ? extractImessagePreview(textContent, fileName)
              : extractNormalPreview(textContent, fileName)

            return preview
          })
        )
      ).filter(Boolean)

      filesPreviews.sort(
        (a, b) => (b?.date.getTime() || 0) - (a?.date.getTime() || 0)
      )
      setZipState((prev) => ({ ...prev, files: filesPreviews as ZipFile[] }))
      setState((prev) => ({ ...prev, isZipDialogOpen: true }))
    }

    const extractImessagePreview = (content: string, fileName: string) => ({
      name: fileName,
      preview: content
        .split('\n')
        .filter((line) => line?.trim())
        .map((line) => line.split(': ').slice(1).join(': '))
        .filter((line) => line?.trim())
        .slice(-3)
        .join('\n'),
      date: new Date(fileName.split('_')[2].split('.')[0]),
    })

    const extractNormalPreview = (content: string, fileName: string) => ({
      name: fileName,
      preview: content
        .split('\n')
        .filter((line) => line?.trim())
        .slice(-3)
        .join('\n'),
      date: new Date(),
    })

    const extractTextFromDb = async (file: File): Promise<string> => {
      const hash = await hashFile(file)
      const fileName = `imessage/${hash}.db`
      await uploadToS3(file, fileName, false, (progress) => {
        console.log('upload progress', progress)
        updateProgress(progress)
      })
      const response = await parseIMessage(fileName, file)
      const rawFile = await fetch(response.presigned_url).then((r) => r.blob())
      await deleteFileWithPresignedUrl(response.presigned_delete_url)
      const newFile = new File([rawFile], 'chat.zip', {
        type: 'application/zip',
      })
      await extractTextFromZip(newFile)
      return ''
    }

    const processFileUpload = async (file: File) => {
      console.log('processing upload', file)
      setState((prev) => ({
        ...prev,
        isProcessing: true,
        error: null,
        uploadProgress: 5,
      }))

      try {
        const sanitizedFileName = sanitizeFilename(file.name)
        const newFile = new File([file], sanitizedFileName, {
          type: file.type,
        })

        if (file.name === 'SampleChat.txt') {
          const text = await file.text()
          const parsedData = await parse(text)
          setParsedData(parsedData)
          await uploadProcess(newFile)
        } else if (file.name.endsWith('.zip')) {
          await extractTextFromZip(newFile)
        } else if (file.name.endsWith('.db')) {
          await extractTextFromDb(newFile)
        } else {
          const text = await file.text()
          const parsedData = await parse(text)
          setParsedData(parsedData)
          await uploadProcess(newFile)
        }
      } catch (error) {
        console.error('Error processing file:', error)
        setState((prev) => ({
          ...prev,
          error: 'Error processing file. Please try again.',
        }))
      } finally {
        setState((prev) => ({
          ...prev,
          isProcessing: false,
          uploadProgress: 0,
        }))
      }
    }

    const handleZipFileSelection = async (fileName: string) => {
      setState((prev) => ({
        ...prev,
        isZipDialogOpen: false,
        isProcessing: true,
        error: null,
        uploadProgress: 0,
      }))

      try {
        if (!zipState.content) {
          throw new Error(
            'Zip content not found. Please try uploading the file again.'
          )
        }

        const sanitizedFileName = sanitizeFilename(fileName)
        const selectedFile = zipState.content.file(fileName)

        if (!selectedFile) {
          throw new Error('Selected file not found in the zip archive.')
        }

        const textContent = await selectedFile.async('string')
        const parsedData = await parse(textContent)
        setParsedData(parsedData)

        const dummyFile = new File([textContent], sanitizedFileName, {
          type: 'text/plain',
        })
        await uploadProcess(dummyFile)
      } catch (error) {
        console.error('Error processing zip file:', error)
        setState((prev) => ({
          ...prev,
          error:
            error instanceof Error
              ? error.message
              : 'An unknown error occurred',
        }))
      } finally {
        setState((prev) => ({
          ...prev,
          isProcessing: false,
          uploadProgress: 0,
        }))
      }
    }

    const onFileUpload = (file: File) => {
      console.log('onFileUpload', file)
      processFileUpload(file)
    }

    return (
      <>
        <WrappedComponent
          {...props}
          onFileUpload={onFileUpload}
          isLoading={state.isProcessing}
          error={state.error}
          zipFiles={zipState.files}
          isZipDialogOpen={state.isZipDialogOpen}
          handleZipFileSelection={handleZipFileSelection}
          setIsZipDialogOpen={(isOpen) =>
            setState((prev) => ({ ...prev, isZipDialogOpen: isOpen }))
          }
          fileInputRef={fileInputRef}
          uploadProgress={state.uploadProgress}
          isProcessing={state.isProcessing}
        />
        {state.isProcessing && (
          <Box
            sx={{ width: '100%', mt: 2, margin: '16px auto', maxWidth: 600 }}
          >
            <LinearProgress
              variant="determinate"
              value={state.uploadProgress}
            />
          </Box>
        )}
        {ZipFileDialog({
          isOpen: state.isZipDialogOpen,
          onClose: () => {
            console.log('onClose')
            setState((prev) => ({ ...prev, isZipDialogOpen: false }))
            setZipState((prev) => ({ ...prev, files: [], content: null }))
            fileInputRef.current!.value = ''
          },
          zipFiles: zipState.files,
          onFileSelect: handleZipFileSelection,
        })}
      </>
    )
  }
}
