import { Box, Button, Container } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGeneralInfoContext } from '../GeneralInfoContext'
import { useS3Fetcher } from '../utils/fetcher'
import { uploadJsonToS3 } from '../utils/s3Storage'
import { theme } from '../utils/theme'
import { ChatMessage, ChatUser, SentimentData } from '../utils/types'
import ChatImageGenerator from './ChatImageGenerator'
import CompatibilityScore from './CompatibilityScore'
import DiscordAndShop from './DiscordAndShop'
import Engagement from './Engagement'
import GradientPaperComponent from './GradientPaper'
import MBTIAnalysis from './MBTIAnalysis'
import MediaRecommendations from './MediaRecommendations'
import SentimentChart from './SentimentChart'
import DrillDownSentiment from './SentimentComponents/DrillDownSentiment'
import Title from './Title'
import UserModal from './UserDashboard/components/UserModal'
import WordCloud from './WordCloud'

const HomePage: React.FC = () => {
  const navigate = useNavigate()
  const [isDrillDown, setIsDrillDown] = useState<boolean>(false)
  const [chartData, setChartData] = useState<SentimentData>({
    sentiments: [],
    allMajorEvents: [],
  })
  const [showUserModal, setShowUserModal] = useState<boolean>(true)
  const { hash, setHash, setToken, setUsers } = useGeneralInfoContext()

  useEffect(() => {
    if (!hash) {
      const urlParams = new URLSearchParams(window.location.search)
      const hashParam = urlParams.get('hash')
      if (hashParam) {
        setHash(hashParam)
      } else {
        navigate('/')
      }
    }
  }, [hash, navigate, setHash])

  useEffect(() => {
    // Add event listener for popstate (back/forward navigation)
    const handlePopState = () => {
      setHash('')
      setToken('')
      console.log(`Hash: ${hash} (popstate)`)
    }

    window.addEventListener('popstate', handlePopState)

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [setHash, setToken])

  async function getUsers(parsedData: ChatMessage[]) {
    // make a hashmap that maps a user to the number of messages they have
    const userMap = new Map<string, number>()
    parsedData.forEach((message) => {
      userMap.set(message.user, (userMap.get(message.user) || 0) + 1)
    })
    // sort the hashmap by the number of messages
    const sortedUsers = Array.from(userMap.entries()).sort(
      (a, b) => b[1] - a[1]
    )
    // take the top 2 users
    const topUsers = sortedUsers.slice(0, 2).map((user) => user[0])
    // sort the top users alphabetically
    topUsers.sort((a, b) => a.localeCompare(b))
    topUsers.map((user) => user.slice(0, 30))
    const result = topUsers.map((user) => ({
      username: user,
      name: user,
      isMe: false,
      edited: false,
    }))
    return result
  }

  const { data: users } = useS3Fetcher<ChatUser[]>({
    generator: getUsers,
    cachePath: 'chat/:hash:/people.json',
  })

  useEffect(() => {
    if (users) {
      setUsers(users)
    }
  }, [users, setUsers])

  const handleSentimentDrillDown = () => {
    setIsDrillDown(true)
    window.scrollTo(0, 0)
  }

  const handleCloseDrillDown = () => {
    setIsDrillDown(false)
  }

  const handleDataFetched = useCallback((data: SentimentData) => {
    setChartData(data)
  }, [])

  return hash ? (
    <Box>
      <CssBaseline />
      <Title />
      <Box
        component="main"
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
      >
        <Container
          maxWidth="lg"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            py: 2,
            px: { xs: 1, sm: 2, md: 3 },
          }}
        >
          <GradientPaperComponent
            elevation={2}
            sx={{
              p: { xs: 2, sm: 3 },
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: isDrillDown ? 'none' : 'block',
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  flexGrow: 1,
                }}
              >
                <Box sx={{ gridColumn: '1 / -1' }}>
                  <Engagement />
                </Box>
                <Box sx={{ gridColumn: '1 / -1', minHeight: 400 }}>
                  <WordCloud />
                </Box>
                <Box sx={{ gridColumn: '1 / -1' }}>
                  <CompatibilityScore />
                </Box>
                <Box sx={{ gridColumn: '1 / -1' }}>
                  <MediaRecommendations />
                </Box>
                {hash && (
                  <Box sx={{ gridColumn: '1 / -1' }}>
                    <SentimentChart
                      onClick={handleSentimentDrillDown}
                      onDataFetched={handleDataFetched}
                    />
                  </Box>
                )}
                <Box sx={{ gridColumn: '1 / -1' }}>
                  <MBTIAnalysis />
                </Box>
                <Box sx={{ gridColumn: '1 / -1' }}>
                  <ChatImageGenerator />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: isDrillDown ? 'block' : 'none',
                flexGrow: 1,
              }}
            >
              <DrillDownSentiment
                chartData={chartData.sentiments}
                allMajorEvents={chartData.allMajorEvents}
                onClose={handleCloseDrillDown}
              />
            </Box>
          </GradientPaperComponent>
        </Container>
      </Box>
      <Box
        component="footer"
        sx={{
          py: 2,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <DiscordAndShop />
          <Button
            variant="outlined"
            href="https://discord.gg/qPTWCdeUgQ"
            target="_blank"
            sx={{
              margin: '20px 10px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: 'white',
              },
            }}
          >
            Join our Discord
          </Button>
        </Container>
      </Box>
      <UserModal
        key={users?.reduce((acc, user) => acc + user.name + user.username, '')}
        open={Boolean(users?.every((user) => !user.edited)) && showUserModal}
        users={users || []}
        onClose={() => {
          setShowUserModal(false)
        }}
        setUsers={(users) => {
          setUsers(users)
          uploadJsonToS3(`chat/${hash}/people.json`, users)
        }}
      />
    </Box>
  ) : null
}

export default HomePage
