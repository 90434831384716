import styled from '@emotion/styled'
import TimelineIcon from '@mui/icons-material/Timeline'
import ViewStreamIcon from '@mui/icons-material/ViewStream'
import {
  alpha,
  Box,
  Chip,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { select } from 'd3-selection'
import { curveBasis, line, Line } from 'd3-shape'
import { transition } from 'd3-transition'
import { format } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MajorEventType } from '../../../../utils/sentimentAnalysis'
import VerticalTimelineView from './HorizontalTimeline'

interface TimelineViewProps {
  events: MajorEventType[]
  chatCategories: Record<string, string>
  chatNames: Record<string, string>
  chatUsers: Record<string, Array<{ username: string; name: string }>>
  minDate: number
  maxDate: number
  onViewModeChange?: () => void
  isVertical?: boolean
  onLayoutChange?: (isVertical: boolean) => void
}

const categoryStyles: Record<string, { color: string; label: string }> = {
  friendship: { color: '#4287f5', label: 'Friend' },
  professional: { color: '#3d3d3d', label: 'Professional' },
  romance: { color: '#e91e63', label: 'Romance' },
  family: { color: '#4caf50', label: 'Family' },
  default: { color: '#9e9e9e', label: 'General' },
}

interface ProcessedEvent extends MajorEventType {
  size: number
  category: string
  eventUsers: Array<{ username: string; name: string }>
  chatName: string
  date: number
  position: number // 0-1 normalized along total timeline
}

interface CategoryFilter {
  [key: string]: boolean
}

export const GradientTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'variant',
})<{ theme?: Theme; variant?: string }>(({ theme }) => ({
  fontWeight: 'bold',
  fontFamily: "'Comfortaa', sans-serif",
  background: `linear-gradient(90deg, 
    #200000 0%,     
    #FF1414 50%,    
    #200000 100%    
  )`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent',
}))

const ChronoBoard: React.FC<TimelineViewProps> = ({
  events,
  chatCategories,
  chatNames,
  chatUsers,
  minDate,
  maxDate,
  onViewModeChange,
  onLayoutChange,
}) => {
  const theme = useTheme()
  const [selectedEvent, setSelectedEvent] = useState<ProcessedEvent | null>(
    null
  )
  const [categoryFilters, setCategoryFilters] = useState<CategoryFilter>(
    Object.keys(categoryStyles).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    )
  )
  const [localIsVertical, setLocalIsVertical] = useState(false)

  const handleLayoutChange = () => {
    setLocalIsVertical(!localIsVertical)
    if (onLayoutChange) onLayoutChange(!localIsVertical)
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [containerHeight, setContainerHeight] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)

  const pathRef = useRef<SVGPathElement>(null)
  const [pathLength, setPathLength] = useState<number>(0)

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        setContainerHeight(rect.height - 140)
        setContainerWidth(rect.width - 50)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const getPathPoints = (width: number, height: number): [number, number][] => {
    const segHeight = height / 3
    const startX = 30
    const endX = width - 40

    return [
      // Top line (horizontal, left -> right)
      [startX, segHeight * 0.5],
      [startX + (endX - startX) * 0.5, segHeight * 0.5],
      [startX + (endX - startX) * 0.9, segHeight * 0.5],
      [endX, segHeight * 0.5],

      // Curve down on right side to second line
      [endX + segHeight / 6, segHeight],
      [endX, segHeight * 1.5],

      // Second line (horizontal, right -> left)
      [endX - (endX - startX) * 0.1, segHeight * 1.5],
      [startX + (endX - startX) * 0.5, segHeight * 1.5],
      [endX - (endX - startX) * 0.9, segHeight * 1.5],
      [startX, segHeight * 1.5],

      // Curve down on left side to third line
      [startX - segHeight / 6, segHeight * 2],
      [startX, segHeight * 2.5],

      // Third line (horizontal, left -> right)
      [startX + (endX - startX) * 0.1, segHeight * 2.5],
      [startX + (endX - startX) * 0.5, segHeight * 2.5],
      [endX, segHeight * 2.5],
    ]
  }

  const pathD = useMemo(() => {
    if (!containerWidth || !containerHeight || localIsVertical) {
      return ''
    }

    const lineGenerator: Line<[number, number]> = line()
      .x((d) => d[0])
      .y((d) => d[1])
      .curve(curveBasis)

    const points = getPathPoints(containerWidth, containerHeight)
    return lineGenerator(points) || ''
  }, [containerWidth, containerHeight, localIsVertical])

  useEffect(() => {
    if (pathRef.current && pathD) {
      const length = pathRef.current.getTotalLength()
      setPathLength(length)
    } else {
      setPathLength(0)
    }
  }, [pathD, localIsVertical])

  useEffect(() => {
    if (!pathRef.current || !pathD) return

    const svgPath = select(pathRef.current)
    const t = transition().duration(1000)

    svgPath.transition(t).attr('d', pathD)
  }, [pathD])

  const processedEvents = useMemo(() => {
    return [...events]
      .sort(
        (a, b) =>
          new Date(a.timestamp_range.start).getTime() -
          new Date(b.timestamp_range.start).getTime()
      )
      .map((event) => {
        const eventDate = new Date(event.timestamp_range.start).getTime()
        const ratio = (eventDate - minDate) / (maxDate - minDate)
        const position = Math.max(0, Math.min(1, ratio))
        const size = Math.max(8, Math.min(16, event.major_score * 2))
        const category = event.hash ? chatCategories[event.hash] : 'default'
        const eventUsers = event.hash ? chatUsers[event.hash] || [] : []
        const chatName = event.hash ? chatNames[event.hash] : 'Unknown Chat'
        return {
          ...event,
          size,
          category,
          eventUsers,
          chatName,
          date: eventDate,
          position,
        } as ProcessedEvent
      })
  }, [events, minDate, maxDate, chatCategories, chatUsers, chatNames])

  const filteredEvents = useMemo(() => {
    return processedEvents.filter((event) => categoryFilters[event.category])
  }, [processedEvents, categoryFilters])

  const renderEventDialog = () => {
    if (!selectedEvent) return null
    const categoryStyle = categoryStyles[selectedEvent.category]

    return (
      <Dialog
        open={!!selectedEvent}
        onClose={() => setSelectedEvent(null)}
        maxWidth="sm"
        fullWidth
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: categoryStyle.color }}
          >
            {format(selectedEvent.date, 'MMMM d, yyyy')}
          </Typography>
          <Typography variant="body1" paragraph>
            {selectedEvent.event}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {selectedEvent.eventUsers.map((user, idx) => (
              <Chip
                key={idx}
                size="small"
                label={user.name}
                sx={{ bgcolor: alpha(categoryStyle.color, 0.1) }}
              />
            ))}
          </Box>
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 2,
              color: theme.palette.text.secondary,
            }}
          >
            Impact Score: {selectedEvent.major_score.toFixed(1)}
          </Typography>
        </Box>
      </Dialog>
    )
  }

  const renderHeader = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        position: 'relative',
        px: 2,
      }}
    >
      <IconButton
        onClick={onViewModeChange}
        size="small"
        sx={{
          position: 'absolute',
          left: 8,
          top: 0,
          bgcolor: theme.palette.background.paper,
          '&:hover': {
            bgcolor: theme.palette.background.default,
          },
        }}
      >
        <TimelineIcon />
      </IconButton>

      <IconButton
        onClick={handleLayoutChange}
        size="small"
        sx={{
          position: 'absolute',
          left: 48,
          top: 0,
          bgcolor: theme.palette.background.paper,
          '&:hover': {
            bgcolor: theme.palette.background.default,
          },
        }}
      >
        <ViewStreamIcon />
      </IconButton>

      <GradientTitle
        variant="h4"
        sx={{
          fontSize: '1.7rem',
          mb: 1,
          width: '100%',
          textAlign: 'center',
        }}
      >
        Events ChronoBoard
      </GradientTitle>

      {!localIsVertical && (
        <Box
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            display: 'flex',
            gap: 1,
          }}
        >
          {Object.entries(categoryStyles).map(([category, style]) => (
            <Chip
              key={category}
              label={style.label}
              size="small"
              sx={{
                bgcolor: alpha(
                  style.color,
                  categoryFilters[category] ? 0.2 : 0.05
                ),
                color: categoryFilters[category]
                  ? style.color
                  : theme.palette.text.disabled,
                borderColor: categoryFilters[category]
                  ? style.color
                  : 'transparent',
                '&:hover': {
                  bgcolor: alpha(style.color, 0.3),
                },
              }}
              onClick={() =>
                setCategoryFilters((prev) => ({
                  ...prev,
                  [category]: !prev[category],
                }))
              }
              variant={categoryFilters[category] ? 'outlined' : 'filled'}
            />
          ))}
        </Box>
      )}
    </Box>
  )

  const renderEvent = (event: ProcessedEvent, index: number) => {
    if (!pathRef.current || !pathLength) return null

    const posLength = event.position * pathLength
    const point = pathRef.current.getPointAtLength(posLength)

    const categoryStyle = categoryStyles[event.category]
    const sentimentColor =
      (event.sentiment ?? 0) >= 0
        ? theme.palette.success.main
        : theme.palette.error.main

    const sentimentIntensity = Math.abs(event.sentiment ?? 0) / 10
    const EVENT_SIZE_BASE = Math.min(32, containerHeight / 4)
    const size = EVENT_SIZE_BASE * (0.5 + sentimentIntensity)

    return (
      <Tooltip
        key={index}
        title={
          <Box>
            <Typography variant="body2">
              {format(event.date, 'MMM d, yyyy')}
            </Typography>
            <Typography variant="body2" sx={{ maxWidth: 200 }}>
              {event.event}
            </Typography>
            <Typography variant="body2" color={sentimentColor}>
              Sentiment: {event.sentiment?.toFixed(1)}
            </Typography>
          </Box>
        }
      >
        <Box
          onClick={() => setSelectedEvent(event)}
          sx={{
            position: 'absolute',
            left: point.x,
            top: point.y,
            width: size,
            height: size,
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              borderRadius: '50%',
              border: `3px solid ${categoryStyle.color}`,
              opacity: 0.8,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '15%',
              left: '15%',
              right: '15%',
              bottom: '15%',
              borderRadius: '50%',
              bgcolor: alpha(sentimentColor, sentimentIntensity),
              boxShadow: `0 0 ${size / 4}px ${alpha(sentimentColor, 0.3)}`,
              transition: 'all 0.2s ease',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              borderRadius: '50%',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
          />
        </Box>
      </Tooltip>
    )
  }

  const renderDateMarkers = () => {
    if (!pathLength || !pathRef.current) return null
    const fractions = [0, 0.25, 0.5, 0.75, 1]
    return fractions.map((frac) => {
      const dist = frac * pathLength
      const point = pathRef.current!.getPointAtLength(dist)
      const dateVal = new Date(minDate + (maxDate - minDate) * frac)

      if (isNaN(dateVal.getTime())) {
        console.error('Invalid date value:', dateVal)
        return null
      }

      return (
        <Typography
          key={frac}
          variant="caption"
          sx={{
            position: 'absolute',
            left: point.x,
            top: point.y - 25,
            transform: 'translate(-50%, -50%)',
            color: theme.palette.text.secondary,
            padding: '2px 4px',
            borderRadius: 1,
            whiteSpace: 'nowrap',
            fontSize: '0.7rem',
          }}
        >
          {format(dateVal, 'MMM yyyy')}
        </Typography>
      )
    })
  }

  const renderCurvePoints = () => {
    if (!containerWidth || !containerHeight || localIsVertical) {
      return null
    }

    const points = getPathPoints(containerWidth, containerHeight)
    return points.map(([x, y], index) => (
      <circle key={index} cx={x} cy={y} r={4} fill="red" opacity={0.7} />
    ))
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        bgcolor: theme.palette.grey[50],
        borderRadius: 2,
        height: '450px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
      ref={containerRef}
    >
      {renderHeader()}

      {localIsVertical ? (
        <VerticalTimelineView
          events={filteredEvents}
          chatCategories={chatCategories}
          chatNames={chatNames}
          chatUsers={chatUsers}
          minDate={minDate}
          maxDate={maxDate}
          onViewModeChange={onViewModeChange}
          isVertical={localIsVertical}
          onLayoutChange={handleLayoutChange}
        />
      ) : (
        <Box
          sx={{
            position: 'relative',
            flexGrow: 1,
            mx: 2,
            mt: 2,
            mb: 1,
            overflow: 'hidden',
          }}
        >
          <svg
            width="100%"
            height="100%"
            style={{ position: 'absolute', left: 0, top: 0 }}
          >
            <path
              ref={pathRef}
              d={pathD}
              fill="none"
              stroke={alpha(theme.palette.divider, 0.5)}
              strokeWidth={3}
            />
            {/* {renderCurvePoints()} */}
          </svg>
          {renderDateMarkers()}
          {filteredEvents.map(renderEvent)}
        </Box>
      )}

      {renderEventDialog()}
    </Paper>
  )
}

export default ChronoBoard
