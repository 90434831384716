import { MajorEventType } from '../../../utils/sentimentAnalysis'
import { getWeekStart } from './dateUtils'

const BASE_WEIGHTS = {
  CURRENT_WEEK: 0.5, // a
  PREVIOUS_WEEK: 0.25, // b
  TWO_WEEKS_AGO: 0.15, // c
  NO_EVENTS: 0.1, // d
} as const

// Ensure that the weights sum up to 1
const TOTAL_WEIGHT =
  BASE_WEIGHTS.CURRENT_WEEK +
  BASE_WEIGHTS.PREVIOUS_WEEK +
  BASE_WEIGHTS.TWO_WEEKS_AGO +
  BASE_WEIGHTS.NO_EVENTS

if (TOTAL_WEIGHT !== 1) {
  throw new Error('Weights do not sum up to 1')
}

interface WeightedData {
  [weekStart: string]: number
}

export function calculateWeightedSentiment(
  chatSentiments: Record<string, Record<string, number>>,
  majorEvents: Record<string, MajorEventType[]>
): WeightedData {
  const weightedData: WeightedData = {}
  const globalSentiments = chatSentiments['global']

  // Pre-process major events into a map of week start timestamps to events
  const eventsByWeek = new Map<number, { sentiment: number; hash: string }[]>()
  console.log('majorEvents', majorEvents)

  majorEvents['global'].forEach((event) => {
    if (!event.hash) return

    const eventTime = new Date(event.timestamp_range.start)
    console.log('eventTime', eventTime)
    const weekStart = new Date(getWeekStart(eventTime)).getTime()
    console.log('weekStart', weekStart)

    if (!eventsByWeek.has(weekStart)) {
      eventsByWeek.set(weekStart, [])
    }
    eventsByWeek.get(weekStart)!.push({
      sentiment: 0, // Will be updated with correct sentiment
      hash: event.hash,
    })
  })

  console.log('eventsByWeek', eventsByWeek)

  Object.entries(globalSentiments).forEach(([weekStart, weekSentiment]) => {
    const currentWeekStart = new Date(weekStart).getTime()
    const prevWeekStart = currentWeekStart - 7 * 24 * 60 * 60 * 1000
    const twoWeeksAgoStart = currentWeekStart - 14 * 24 * 60 * 60 * 1000

    // Get events for each week period using our pre-processed map
    const EW = (eventsByWeek.get(currentWeekStart) || []).map((e) => ({
      ...e,
      sentiment: weekSentiment,
    }))
    const EW_1 = (eventsByWeek.get(prevWeekStart) || []).map((e) => ({
      ...e,
      sentiment: weekSentiment,
    }))
    const EW_2 = (eventsByWeek.get(twoWeeksAgoStart) || []).map((e) => ({
      ...e,
      sentiment: weekSentiment,
    }))
    const EN0 =
      EW.length === 0 && EW_1.length === 0 && EW_2.length === 0
        ? [{ sentiment: weekSentiment, hash: 'no-events' }]
        : []

    // Calculate average sentiments for each category
    const avgEW =
      EW.length > 0
        ? EW.reduce((sum, chat) => sum + chat.sentiment, 0) / EW.length
        : 0
    const avgEW1 =
      EW_1.length > 0
        ? EW_1.reduce((sum, chat) => sum + chat.sentiment, 0) / EW_1.length
        : 0
    const avgEW2 =
      EW_2.length > 0
        ? EW_2.reduce((sum, chat) => sum + chat.sentiment, 0) / EW_2.length
        : 0
    const avgEN0 =
      EN0.length > 0
        ? EN0.reduce((sum, chat) => sum + chat.sentiment, 0) / EN0.length
        : 0

    // Determine which categories are present
    let sumWeightsPresent = 0
    const normalizedWeights: Partial<
      Record<keyof typeof BASE_WEIGHTS, number>
    > = {}

    if (EW.length > 0) {
      normalizedWeights.CURRENT_WEEK = BASE_WEIGHTS.CURRENT_WEEK
      sumWeightsPresent += BASE_WEIGHTS.CURRENT_WEEK
    }
    if (EW_1.length > 0) {
      normalizedWeights.PREVIOUS_WEEK = BASE_WEIGHTS.PREVIOUS_WEEK
      sumWeightsPresent += BASE_WEIGHTS.PREVIOUS_WEEK
    }
    if (EW_2.length > 0) {
      normalizedWeights.TWO_WEEKS_AGO = BASE_WEIGHTS.TWO_WEEKS_AGO
      sumWeightsPresent += BASE_WEIGHTS.TWO_WEEKS_AGO
    }
    if (EN0.length > 0) {
      normalizedWeights.NO_EVENTS = BASE_WEIGHTS.NO_EVENTS
      sumWeightsPresent += BASE_WEIGHTS.NO_EVENTS
    }

    // Normalize the weights so they sum up to 1
    Object.keys(normalizedWeights).forEach((key) => {
      const weightKey = key as keyof typeof BASE_WEIGHTS
      normalizedWeights[weightKey] =
        normalizedWeights[weightKey]! / sumWeightsPresent
    })

    // Calculate the weighted sentiment
    let weightedSentiment = 0

    if (normalizedWeights.CURRENT_WEEK && EW.length > 0) {
      weightedSentiment += normalizedWeights.CURRENT_WEEK * avgEW
    }
    if (normalizedWeights.PREVIOUS_WEEK && EW_1.length > 0) {
      weightedSentiment += normalizedWeights.PREVIOUS_WEEK * avgEW1
    }
    if (normalizedWeights.TWO_WEEKS_AGO && EW_2.length > 0) {
      weightedSentiment += normalizedWeights.TWO_WEEKS_AGO * avgEW2
    }
    if (normalizedWeights.NO_EVENTS && EN0.length > 0) {
      weightedSentiment += normalizedWeights.NO_EVENTS * avgEN0
    }

    // Assign to weightedData
    weightedData[weekStart] = weightedSentiment

    console.log(`Week ${weekStart} calculation:`, {
      sentimentSets: {
        EW,
        EW_1,
        EW_2,
        EN0,
      },
      averages: {
        avgEW,
        avgEW1,
        avgEW2,
        avgEN0,
      },
      sumWeightsPresent,
      normalizedWeights,
      weightedSentiment,
    })
  })

  return weightedData
}
