import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  alpha,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip as MuiTooltip,
  Paper,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { format, subYears } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getWeekStart } from '../../components/UserDashboard/components/dateUtils'
import { useGeneralInfoContext } from '../../GeneralInfoContext'
import { getUserName, updateUserReferences } from '../../utils/general'
import {
  MajorEventType,
  SentimentResponseComplete,
} from '../../utils/sentimentAnalysis'

export type EventMessage = {
  user: string
  message: string
  isContext: boolean
  isAfterContext: boolean
  timestamp: string
}

interface SentimentChartDrillDownProps {
  chartData: SentimentResponseComplete[]
  selectedEventIndex: number | null
}

const SentimentChartDrillDown: React.FC<SentimentChartDrillDownProps> = ({
  chartData,
  selectedEventIndex,
}) => {
  const theme = useTheme()
  const { users } = useGeneralInfoContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedEvent, setSelectedEvent] = useState<MajorEventType | null>(
    null
  )
  const [dateRange, setDateRange] = useState<[number, number]>([0, 100])

  const majorEvents = useMemo(() => {
    return chartData.flatMap((week) => week.major_events || [])
  }, [chartData])

  const sortedEvents = useMemo(() => {
    return [...majorEvents].sort((a, b) => {
      const dateA = a?.timestamp_range?.start
        ? new Date(a.timestamp_range.start)
        : new Date(0)
      const dateB = b?.timestamp_range?.start
        ? new Date(b.timestamp_range.start)
        : new Date(0)
      return dateA.getTime() - dateB.getTime()
    })
  }, [majorEvents])

  useEffect(() => {
    if (
      selectedEventIndex !== null &&
      selectedEventIndex >= 0 &&
      selectedEventIndex < sortedEvents.length
    ) {
      setSelectedEvent(sortedEvents[selectedEventIndex])
    }
  }, [selectedEventIndex, sortedEvents])

  const renderCustomDot = (props: any) => {
    const { cx, cy, payload, dataKey } = props
    const weekStart = new Date(payload.weekStartTimestamp).getTime()
    const weekEnd = weekStart + 7 * 24 * 60 * 60 * 1000

    // Find all major events within the week
    const eventsInWeek = sortedEvents.filter((event) => {
      const eventStart = new Date(event.timestamp_range.start).getTime()
      return eventStart >= weekStart && eventStart <= weekEnd
    })

    // If there are events, render the custom dot with emojis
    if (eventsInWeek.length > 0) {
      const xIsHigher = payload.X_sentiment > payload.Z_sentiment
      const shouldShowEmojis =
        (xIsHigher && dataKey === 'X_sentiment') ||
        (!xIsHigher && dataKey === 'Z_sentiment')

      if (shouldShowEmojis) {
        return (
          <g>
            <circle 
              cx={cx} 
              cy={cy} 
              r={3}
              fill="#fff"
              stroke={props.stroke}
              strokeWidth={2}
            />
            {eventsInWeek.map((event: MajorEventType, eventIndex: number) => {
              const isSelectedEvent =
                selectedEvent &&
                selectedEvent.timestamp_range.start ===
                  event.timestamp_range.start

              return event.emojis.map((emoji: string, emojiIndex: number) => (
                <g key={`${eventIndex}-${emojiIndex}`}>
                  <line
                    x1={cx}
                    y1={cy}
                    x2={cx + (eventIndex - eventsInWeek.length / 2) * 20}
                    y2={20 + emojiIndex * 20}
                    stroke={props.stroke}
                    strokeWidth={1}
                    strokeDasharray="3,3"
                    style={{ opacity: isSelectedEvent ? 0.6 : 0.3 }}
                  />
                  <text
                    x={cx + (eventIndex - eventsInWeek.length / 2) * 20}
                    y={20 + emojiIndex * 20}
                    textAnchor="middle"
                    fontSize={20}
                    style={{
                      cursor: 'pointer',
                      opacity: selectedEvent ? (isSelectedEvent ? 1 : 0.3) : 1,
                    }}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setSelectedEvent(isSelectedEvent ? null : event);
                    }}
                  >
                    {emoji}
                  </text>
                </g>
              ))
            })}
          </g>
        )
      }
    }
    
    // For non-event points, use Recharts default dot styling
    return (
      <circle 
        cx={cx} 
        cy={cy} 
        r={3}
        fill="#fff"
        stroke={props.stroke}
        strokeWidth={2}
      />
    )
  }

  const [user1, user2] = users || []
  const user1Color = theme.palette.primary.main
  const user2Color = theme.palette.secondary.main

  const chartDataWithTimestamps = useMemo(() => {
    return chartData.map((dataPoint) => {
      return {
        ...dataPoint,
        weekStartTimestamp: new Date(
          getWeekStart(dataPoint.weekStart)
        ).getTime(),
      }
    })
  }, [chartData])

  const minDate =
    chartDataWithTimestamps.length > 0
      ? chartDataWithTimestamps[0].weekStartTimestamp
      : 0
  const maxDate =
    chartDataWithTimestamps.length > 0
      ? chartDataWithTimestamps[chartDataWithTimestamps.length - 1]
          .weekStartTimestamp
      : 0

  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectedEvent) {
        const tooltip = document.getElementById('selected-event-messages')
        if (tooltip && !tooltip.contains(event.target as Node)) {
          setSelectedEvent(null)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside as EventListener)
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside as EventListener
      )
    }
  }, [selectedEvent])

  useEffect(() => {
    if (chartDataWithTimestamps.length === 0) return
    const oneYearAgo = subYears(new Date(maxDate), 1).getTime()
    if (minDate === maxDate) {
      setDateRange([0, 100])
      return
    }
    const defaultStartDate = Math.max(oneYearAgo, minDate)
    const defaultStartPercentage =
      ((defaultStartDate - minDate) / (maxDate - minDate)) * 100
    setDateRange([defaultStartPercentage, 100])
  }, [chartDataWithTimestamps.length, minDate, maxDate])

  const filteredChartData = chartDataWithTimestamps.filter((data) => {
    const percentage =
      ((data.weekStartTimestamp - minDate) / (maxDate - minDate)) * 100
    return percentage >= dateRange[0] && percentage <= dateRange[1]
  })

  console.log('filteredChartData', filteredChartData)

  const handleDateRangeChange = (event: Event, newValue: number | number[]) => {
    setDateRange(newValue as [number, number])
  }

  const valuetext = (value: number) => {
    if (chartDataWithTimestamps.length === 0) return ''
    const date = new Date(minDate + ((maxDate - minDate) * value) / 100)
    return format(date, 'MMM d, yyyy')
  }

  const renderCustomTooltip = ({ active, payload, label, coordinate }: any) => {
    if (active && payload && payload.length && chartRef.current) {
      const data = payload[0].payload
      const [user1] = users || []
      const tooltipWidth = 500

      // Calculate position based on chart container midpoint instead of screen
      const chartBounds = chartRef.current.getBoundingClientRect()
      const chartMidpoint = chartBounds.left + chartBounds.width / 2
      const isPastMidpoint = coordinate?.x + chartBounds.left > chartMidpoint
      const xPosition = isPastMidpoint
        ? coordinate?.x - tooltipWidth - 10
        : coordinate?.x + 10
      const yPosition = coordinate?.y - 100

      return (
        <Paper
          sx={{
            p: theme.spacing(2),
            width: tooltipWidth,
            position: 'absolute',
            top: yPosition,
            left: xPosition,
            pointerEvents: 'none',
            zIndex: 5,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[4],
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            {`Week of ${format(new Date(label || ''), 'MMM d, yyyy')}`}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Summary Quote
          </Typography>
          {data.summary_quote ? (
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'bold',
                  color:
                    data.summary_quote.user === user1.username
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                  mb: 1,
                }}
              >
                {getUserName(users || [], data.summary_quote.user)}
              </Typography>
              <Box
                sx={{
                  bgcolor:
                    data.summary_quote.user === user1.username
                      ? theme.palette.primary.light
                      : theme.palette.secondary.light,
                  p: 1,
                  borderRadius: theme.shape.borderRadius / 2,
                  fontSize: '0.8rem',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {data.summary_quote.quote}
              </Box>
            </Box>
          ) : (
            <Typography variant="body2" sx={{ fontSize: '0.8rem', mb: 2 }}>
              No summary quote available
            </Typography>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1, pr: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Salient Events:
              </Typography>
              <List dense sx={{ fontSize: '0.8rem' }}>
                {data.salient_events
                  ?.sort((a: any, b: any) => b.salience - a.salience)
                  .map((event: any, index: number) => (
                    <ListItem key={index} sx={{ p: 0 }}>
                      <ListItemText
                        primary={updateUserReferences(event.event, users || [])}
                      />
                    </ListItem>
                  ))}
              </List>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Top Quotes:
              </Typography>
              {data.top_quotes?.map((quote: any, index: number) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      color:
                        quote.user === user1.username
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main,
                      mb: 1,
                    }}
                  >
                    {getUserName(users || [], quote.user)}
                  </Typography>
                  <Box
                    sx={{
                      bgcolor:
                        quote.user === user1.username
                          ? theme.palette.primary.light
                          : theme.palette.secondary.light,
                      p: 1,
                      borderRadius: theme.shape.borderRadius / 2,
                      fontSize: '0.8rem',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word',
                    }}
                  >
                    {quote.quote}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Paper>
      )
    }
    return null
  }

  if (!chartData || chartData.length === 0) {
    return <Typography>No data available for the chart.</Typography>
  }

  return (
    <>
      <Box
        sx={{
          height: '500px',
          width: '100%',
        }}
      >
        <Paper
          elevation={3}
          ref={chartRef}
          sx={{
            p: 2,
            bgcolor: theme.palette.grey[50],
            borderRadius: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={4} />
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Detailed Sentiment Analysis
                  </Typography>
                  <MuiTooltip
                    title={
                      <Typography sx={{ p: 1 }}>
                        This chart shows major events that occurred during your
                        chat history. An emoji pair represents the general vibe
                        of your talk and clicking on them will reveal deeper
                        insights on this moment.
                        <br />
                      </Typography>
                    }
                  >
                    <InfoIcon
                      color="primary"
                      sx={{
                        ml: 1,
                        cursor: 'help',
                        fontSize: '1.2rem',
                        verticalAlign: 'middle',
                      }}
                    />
                  </MuiTooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              width: '100%',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <ResponsiveContainer width="100%" style={{ overflow: 'visible' }}>
              <LineChart
                data={filteredChartData}
                margin={{ top: 50, right: 30, left: 20, bottom: 10 }}
                style={{ isolation: 'isolate' }}
              >
                <XAxis
                  dataKey="weekStartTimestamp"
                  type="number"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={(tickItem) =>
                    format(new Date(tickItem), 'MMM d, yyyy')
                  }
                  interval={
                    (isMobile ? 2 : 1) * Math.ceil(chartData.length / 10)
                  }
                  tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                />
                <YAxis
                  domain={[-10, 10]}
                  tick={{ fill: theme.palette.text.secondary, fontSize: 12 }}
                />
                <Legend verticalAlign="bottom" height={36} />
                <Line
                  type="monotoneX"
                  dataKey="X_sentiment"
                  name={user1.name}
                  stroke={user1Color}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                  dot={renderCustomDot}
                />
                <Line
                  type="monotoneX"
                  dataKey="Z_sentiment"
                  name={user2.name}
                  stroke={user2Color}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                  dot={renderCustomDot}
                />
                <Tooltip content={renderCustomTooltip} />
              </LineChart>
            </ResponsiveContainer>
            {}

            {/* Selected event messages */}
            {selectedEvent && (
              <Box
                id="selected-event-messages"
                sx={{
                  position: 'absolute',
                  top: 50,
                  left: 0,
                  width: '100%',
                  maxWidth: '1200px',
                  maxHeight: '600px',
                  height: '600px',
                  overflow: 'hidden',
                  bgcolor: theme.palette.background.paper,
                  p: 2,
                  borderRadius: 2,
                  boxShadow: theme.shadows[5],
                  display: 'flex',
                }}
              >
                <IconButton
                  sx={{ position: 'absolute', top: 5, right: 5 }}
                  onClick={() => setSelectedEvent(null)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>

                {/* Event Details Section */}
                <Box
                  sx={{
                    width: '50%',
                    pr: 2,
                    borderRight: 1,
                    borderColor: 'divider',
                    overflowY: 'auto',
                  }}
                >
                  {/* Event Header Section */}
                  <Box
                    sx={{
                      mb: 3,
                      borderBottom: 1,
                      borderColor: 'divider',
                      pb: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {selectedEvent.emojis.map((emoji, i) => (
                        <span key={i} style={{ marginRight: '8px' }}>
                          {emoji}
                        </span>
                      ))}
                      {selectedEvent.event}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {format(
                        new Date(selectedEvent.timestamp_range.start),
                        'PPP'
                      )}{' '}
                      -{' '}
                      {format(
                        new Date(selectedEvent.timestamp_range.end),
                        'PPP'
                      )}
                    </Typography>
                  </Box>

                  {/* Event Details Content */}
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Event Overview
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {/* Event detail texts */}
                      <Typography variant="body1" paragraph>
                        <strong>Summary:</strong>{' '}
                        {updateUserReferences(
                          selectedEvent.event_deep_dive.event_summary,
                          users || []
                        )}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Key Interactions:</strong>{' '}
                        {updateUserReferences(
                          selectedEvent.event_deep_dive.key_interactions,
                          users || []
                        )}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Emotional Impact:</strong>{' '}
                        {updateUserReferences(
                          selectedEvent.event_deep_dive.emotional_responses,
                          users || []
                        )}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Impact on Relationship:</strong>{' '}
                        {updateUserReferences(
                          selectedEvent.event_deep_dive.potential_impact,
                          users || []
                        )}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Psychological Analysis:</strong>{' '}
                        {updateUserReferences(
                          selectedEvent.event_deep_dive
                            .psychoanalytical_takeaway,
                          users || []
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Messages Section */}
                <Box
                  sx={{
                    width: '50%',
                    pl: 2,
                    overflowY: 'auto',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Related Messages
                  </Typography>
                  <Box>
                    {selectedEvent.eventMessages.map((message, index) => {
                      const isUser1 = message.user === user1.username
                      const baseColor = isUser1 ? user1Color : user2Color
                      let backgroundColor = message.isContext
                        ? alpha(baseColor, message.isAfterContext ? 0.2 : 0.1)
                        : baseColor

                      const displayName = isUser1 ? user1.name : user2.name

                      return (
                        <Box
                          key={index}
                          sx={{
                            backgroundColor,
                            p: 1,
                            mb: 1,
                            borderRadius: 1,
                            color: message.isContext ? 'inherit' : 'white',
                          }}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              mb: 0.5,
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                              {displayName}
                            </span>
                            <span
                              style={{
                                fontSize: '0.8em',
                                color: message.isContext
                                  ? theme.palette.text.secondary
                                  : 'inherit',
                              }}
                            >
                              {format(
                                new Date(message.timestamp),
                                'MMM d, yyyy HH:mm:ss'
                              )}
                            </span>
                          </Typography>
                          <Typography variant="body2" component="p">
                            {message.message}
                          </Typography>
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ px: 3, pt: 2, mt: -2 }}>
            <Slider
              value={dateRange}
              onChange={handleDateRangeChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valuetext}
              getAriaValueText={valuetext}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: '0.75rem',
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 1,
                color: theme.palette.text.secondary,
                fontSize: '0.75rem',
              }}
            >
              <span>{valuetext(dateRange[0])}</span>
              <span>{valuetext(dateRange[1])}</span>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default SentimentChartDrillDown
