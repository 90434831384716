import { ThemeProvider } from "@emotion/react";
import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { SWRConfig } from "swr";
import "./aws-config";
import PrivacyPolicyPage from "./components/Documents/PrivacyPolicyPage";
import TermsOfServicePage from "./components/Documents/TermsOfServicePage";
import HomePage from "./components/HomePage";
import LandingPage from "./components/LandingPage";
import ShopSection from "./components/ShopSection";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import UserDashboard from "./components/UserDashboard";
import { GeneralInfoProvider } from "./GeneralInfoContext";
import { theme } from "./utils/theme";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GeneralInfoProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/main"
              element={
                <SWRConfig value={{ provider: () => new Map() }}>
                  <HomePage />
                </SWRConfig>
              }
            />
            <Route path="/shop" element={<ShopSection />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="*" element={<Navigate to="/signin" replace />} />
          </Routes>
        </Router>
      </GeneralInfoProvider>
    </ThemeProvider>
  );
};

export default App;
